import { v4 as uuidv4 } from "uuid";
import { Interactive } from "../models/implementables";
import * as paper from "paper";
import { DiagramLayer } from "../shared/layer";
import { DiagramService } from "../shared/services/diagram.service";
import { DiagramElementAction } from "../shared/Action";
import { globalizeBounds } from "../shared/helpers";

export abstract class DiagramElement implements Interactive {
    entireObject: paper.Item;

    get originalLayer() {
        return this.entireObject.data.originalLayer;
    }

    set originalLayer(layer: DiagramLayer) {
        this.entireObject.data.originalLayer = layer;
    }

    get className() {
        return this.entireObject.data.className;
    }

    set className(className) {
        this.entireObject.data.className = className;
    }

    get id() {
        return this.entireObject.data.referenceId;
    }

    set id(id: string) {
        this.entireObject.data.referenceId = id;
    }

    get position() {
        return this.entireObject.position;
    }

    set position(p: paper.Point) {
        this.entireObject.position = p;
    }

    constructor(entireObject: paper.Item) {
        this.entireObject = entireObject;
        this.entireObject.data.isInteractive = true;
        if (!this.originalLayer) {
            this.originalLayer = DiagramLayer[paper.projects[0].activeLayer.name];
        }
        if (!this.id) {
            this.id = this.getUniqueId();
            this.applyClassName();
        }

        this.setUpEventHandlers();
        DiagramService.addElement(this);
    }

    /** tldr: Classes that get restored (see diagram-service.ts) must use this function
     * to set this.className to a hardcoded string matching the subclass's name.
     *
     * This is so that file minification does not corrupt the subclasses map, which was
     * the class when DiagramElement's constructor was setting className = this.constructor.name.
     */
    protected abstract applyClassName(): void;

    /** A unique ID independent from paper.js survives imports/exports */
    protected getUniqueId(): string {
        return uuidv4();
    }

    abstract setUpEventHandlers(): void;

    /** Base removal code. Deletes the entry in objectMap and removes the entireObject from the paper project.
     * @todo Must override in subclasses to first remove connected objects if applicable (arrows, trailers, etc)
     * then call super.remove() last.
     */
    remove(): void {
        let removeAction: DiagramElementAction | undefined = undefined;

        if (DiagramElementAction.prepareForNewAction()) {
            removeAction = new DiagramElementAction({
                name: "remove",
                elementRef: this,
                isLocked: true
            });
            removeAction.startRecording();
        }

        DiagramService.removeElement(this);
        this.entireObject.remove();

        if (removeAction) {
            removeAction.stopRecording();
        }
    }

    getGlobalBounds() {
        return globalizeBounds(this.entireObject.bounds, this.entireObject);
    }

    onAddToGroupSelection() {}
    onRemoveFromGroupSelection() {}
}
