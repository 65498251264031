import {
    Component,
    ElementRef,
    Input,
    AfterViewInit,
    ViewChild,
    ChangeDetectorRef,
    OnInit,
    ChangeDetectionStrategy,
    Renderer2
} from "@angular/core";
import * as L from "leaflet";
import * as paper from "paper";
import {
    BASE_ZOOM,
    currentOpacity,
    getCrashLatLngAsPaperPoint,
    latLngToProjectPoint,
    projectPointToLatLng,
    setMapOpacity,
    diagramStyle
} from "../shared/diagram-map";
import { InsetData, UIService } from "../shared/services";
import { normalizeInsetScale, positionViewBoxNumber } from "../shared/helpers";
import Point from "../shared/S4Point";
import {
    repositionInsets,
    restoreSelectedBounds,
    stretchBoundsForViewBox
} from "../cdt-diagram/cdt-diagram-cropping";
import { DiagramLayer, getLayer } from "../shared/layer";

@Component({
    selector: "inset-tool",
    templateUrl: "./inset-tool.component.html",
    styleUrls: ["./inset-tool.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsetToolComponent implements AfterViewInit, OnInit {
    static readonly MIN_DIM = 100;
    static readonly MAX_DIM = window.screen.availWidth / 4;

    @ViewChild("diagram")
    private diagramCanvasRef: ElementRef<HTMLCanvasElement>;
    @ViewChild("inset")
    private mapDivRef: ElementRef<HTMLDivElement>;
    @ViewChild("textboxes")
    private textboxDivRef: ElementRef<HTMLDivElement>;

    @Input("options")
    options: InsetData;

    private map: L.Map;
    private project: paper.Project;
    private viewBox: paper.Group;
    private viewBoxBorder: paper.Path;
    private viewBoxNumber: paper.Group;

    get view() {
        return this.project.view;
    }

    get insetNumber() {
        return this.ui.insets.indexOf(this.options) + 1;
    }

    get diagramStyle() {
        return diagramStyle;
    }

    constructor(
        private ui: UIService,
        public changeDetector: ChangeDetectorRef,
        private renderer: Renderer2
    ) {
        this.onDragResize = this.onDragResize.bind(this);
        this.endDragResize = this.endDragResize.bind(this);
        this.onDragMove = this.onDragMove.bind(this);
        this.endDragMove = this.endDragMove.bind(this);
    }

    ngAfterViewInit(): void {
        const diagramJson = paper.projects[0].exportJSON();
        paper.setup(this.diagramCanvasRef.nativeElement);
        this.project = paper.project;
        this.project.clear();
        this.project.importJSON(diagramJson);
        paper.projects[0].activate();
        const viewBoxes = this.project.getItems({ data: { isViewBox: true } });
        for (const viewBox of viewBoxes) {
            viewBox.remove();
        }
        const croppingUis = this.project.getItems({
            data: { isCroppingUi: true }
        });
        for (const ui of croppingUis) {
            ui.remove();
        }
        this.setUpMap(this.options.latLng);
        this.project.view.zoom = this.getZoomScale();
        this.repositionDiagram();
        this.setLatLng();
        this.setUpMapHandlers();
        this.createViewBox();
        this.updateViewBox();
        this.createTextAreas();
        repositionInsets();
    }

    ngOnInit(): void {
        this.options.component = this;
    }

    private setUpMap(latLng: L.LatLng) {
        const googleLayer = L.tileLayer(
            "https://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}",
            {
                maxZoom: 22,
                minZoom: 19,
                subdomains: ["mt0", "mt1", "mt2", "mt3"]
            }
        );
        this.map = L.map(this.mapDivRef.nativeElement, {
            center: latLng,
            zoom: 21,
            layers: [googleLayer],
            zoomControl: false,
            attributionControl: false
        });

        this.map.boxZoom.disable();
        this.map.doubleClickZoom.disable();
        setMapOpacity(currentOpacity, this.map); // set the default opacity
        if (diagramStyle !== "Color") {
            this.map.getPanes().mapPane.style.filter = "grayscale(1)";
        }
    }

    private createTextAreas() {
        if (this.renderer && this.textboxDivRef?.nativeElement) {
            const textboxes = this.project.getItems({
                data: { className: "TextboxTool" }
            });
            const div = this.textboxDivRef.nativeElement;

            for (let box of textboxes) {
                box = box.children["shape" as any];
                const textArea = this.renderer.createElement(
                    "textarea"
                ) as HTMLTextAreaElement;

                this.renderer.setStyle(textArea, "pointerEvents", "none");
                this.renderer.setProperty(textArea, "readOnly", true);
                this.renderer.addClass(textArea, "textbox");

                const rect = this.getTextAreaRectV2(box);

                this.renderer.setStyle(textArea, "width", `${rect.width}px`);
                this.renderer.setStyle(textArea, "height", `${rect.height}px`);
                this.renderer.setStyle(textArea, "left", `${rect.x}px`);
                this.renderer.setStyle(textArea, "top", `${rect.y}px`);
                this.renderer.setStyle(
                    textArea,
                    "transform",
                    `rotate(${box.parent.rotation}deg)`
                );
                this.renderer.setStyle(
                    textArea,
                    "fontSize",
                    `${box.data.fontSize}px`
                );
                this.renderer.setStyle(textArea, "color", box.data.fontColor);

                if (box.data.isBold) {
                    this.renderer.setStyle(textArea, "fontWeight", "bold");
                } else {
                    this.renderer.removeStyle(textArea, "font-weight");
                }

                textArea.value = box.data.content;
                this.renderer.appendChild(div, textArea);
            }
        } else {
            console.error("Either no Renderer or elementRef could be found.");
        }
    }

    private getTextAreaRectV2(textbox: paper.Item) {
        const rect = new paper.Rectangle(textbox.internalBounds).expand(-10);
        const center = textbox.localToGlobal(textbox.position);
        rect.center = center.subtract(textbox.layer.position);

        return rect;
    }

    private getZoomScale() {
        return this.map.getZoomScale(this.map.getZoom(), BASE_ZOOM);
    }

    private setLatLng() {
        const latLngPoint = getCrashLatLngAsPaperPoint(this.project, this.map);
        const crashPointObject = this.project.getItem({
            data: { isCrashIcon: true }
        });
        crashPointObject.position = latLngPoint;

        for (const layer of this.project.layers) {
            layer.pivot = latLngPoint;
        }
    }

    private createViewBox() {
        const existing = paper.projects[0].getItem({
            data: { insetNumber: this.insetNumber }
        });

        if (existing) {
            this.viewBox = existing as paper.Group;
            this.viewBoxBorder = this.viewBox.firstChild as paper.Path;
            this.viewBoxNumber = this.viewBox.lastChild as paper.Group;
            this.viewBox.visible = true;
            this.updateNumber();
            stretchBoundsForViewBox();
            return;
        }

        getLayer(DiagramLayer.foregroundAnnotations).activate();

        this.viewBoxBorder = new paper.Path.Rectangle(
            latLngToProjectPoint(
                projectPointToLatLng(
                    this.view.bounds.topLeft,
                    this.project,
                    this.map
                )
            ),
            latLngToProjectPoint(
                projectPointToLatLng(
                    this.view.bounds.bottomRight,
                    this.project,
                    this.map
                )
            )
        );
        this.viewBoxBorder.strokeColor = new paper.Color("black");
        this.viewBoxBorder.strokeWidth = 2;
        this.viewBoxBorder.dashArray = [4, 6];
        const numberBg = new paper.Path.Rectangle(
            this.viewBoxBorder.bounds.topRight.add([-25, 0]),
            [25, 25]
        );
        numberBg.strokeColor = new paper.Color("black");
        numberBg.fillColor = new paper.Color(255, 255, 255, 0.75);
        const numberTxt = new paper.PointText({
            content: this.insetNumber.toString(),
            fontSize: 16,
            fontWeight: "bold",
            strokeWidth: 1
        });

        numberTxt.position = numberBg.position;
        // numberTxt.content = this.insetNumber.toString();

        this.viewBoxNumber = new paper.Group([numberBg, numberTxt]);
        this.viewBoxNumber.applyMatrix = false;
        this.viewBox = new paper.Group([
            this.viewBoxBorder,
            this.viewBoxNumber
        ]);
        this.viewBox.data.insetNumber = this.insetNumber;
        this.viewBox.data.isViewBox = true;
        normalizeInsetScale(this.viewBox);
        getLayer(DiagramLayer.cropping).activate();
        stretchBoundsForViewBox();
    }

    private updateNumber() {
        const number = this.viewBoxNumber.children[1] as paper.PointText;
        number.content = this.insetNumber.toString();
    }

    remove() {
        this.viewBox.remove();
        this.map.remove();
        this.ui.insets.splice(this.insetNumber - 1, 1);
        for (const inset of this.ui.insets) {
            inset.component?.updateNumber();
        }
        restoreSelectedBounds();
        repositionInsets();
    }

    private updateViewBox() {
        this.view.viewSize = new paper.Size(
            this.options.width,
            this.options.height
        );
        this.viewBoxBorder.bounds = new paper.Rectangle(
            latLngToProjectPoint(
                projectPointToLatLng(
                    this.view.bounds.topLeft,
                    this.project,
                    this.map
                )
            ),
            latLngToProjectPoint(
                projectPointToLatLng(
                    this.view.bounds.bottomRight,
                    this.project,
                    this.map
                )
            )
        );
        positionViewBoxNumber(this.viewBoxBorder, this.viewBoxNumber);
    }

    private repositionDiagram() {
        const latLngPoint = getCrashLatLngAsPaperPoint(this.project, this.map);
        this.options.latLng = this.map.getCenter();

        for (const layer of this.project.layers) {
            layer.position = latLngPoint;
        }

        this.repositionTextboxes(latLngPoint);
    }

    private repositionTextboxes(p: paper.Point) {
        if (!this.textboxDivRef?.nativeElement) return;

        p = this.view.projectToView(p);
        this.renderer.setStyle(
            this.textboxDivRef.nativeElement,
            "left",
            `${p.x}px`
        );
        this.renderer.setStyle(
            this.textboxDivRef.nativeElement,
            "top",
            `${p.y}px`
        );
    }

    private setUpMapHandlers() {
        this.map.on("move", () => {
            this.repositionDiagram();
            this.updateViewBox();
            stretchBoundsForViewBox();
        });

        this.map.on("zoom", () => {
            const zoomScale = this.getZoomScale();
            this.project.view.zoom = zoomScale;
            normalizeInsetScale(this.viewBox);
            this.textboxDivRef.nativeElement.style.transform = `scale(${zoomScale})`;
        });
    }

    startDragMove(e: MouseEvent) {
        document.addEventListener("mousemove", this.onDragMove);
        document.addEventListener("mouseup", this.endDragMove);
        e.stopPropagation();
        e.preventDefault();
    }

    startDragResize(e: MouseEvent) {
        document.addEventListener("mousemove", this.onDragResize);
        document.addEventListener("mouseup", this.endDragResize);
        e.stopPropagation();
        e.preventDefault();
    }

    onDragMove(e: MouseEvent) {
        this.options.point = new Point(e.x - 13, e.y - 66 - 13);
        this.updateViewBox();
    }

    endDragMove() {
        document.removeEventListener("mousemove", this.onDragMove);
        document.removeEventListener("mouseup", this.endDragMove);
    }

    onDragResize(e: MouseEvent) {
        const p = this.options.point;
        let dx = e.x - p.x;
        let dy = e.y - p.y - 66;

        if (dx < InsetToolComponent.MIN_DIM) dx = InsetToolComponent.MIN_DIM;
        if (dy < InsetToolComponent.MIN_DIM) dy = InsetToolComponent.MIN_DIM;
        if (dx > InsetToolComponent.MAX_DIM) dx = InsetToolComponent.MAX_DIM;
        if (dy > InsetToolComponent.MAX_DIM) dy = InsetToolComponent.MAX_DIM;

        this.options.width = dx;
        this.options.height = dy;
        this.updateViewBox();
        this.changeDetector.detectChanges();
        // stretchBoundsForInset(this.options);
    }

    endDragResize() {
        document.removeEventListener("mousemove", this.onDragResize);
        document.removeEventListener("mouseup", this.endDragResize);
        stretchBoundsForViewBox();
    }
}
