import { DiagramObject } from "src/app/classes/DiagramObject";
import { DiagramService } from "./../shared/services/diagram.service";
import { environment } from "./../../environments/environment";
import { AfterContentInit, Component, ViewChild } from "@angular/core";
import { promptBox } from "../page-disabler/disable-enable-page";
import {
    confirmOrCancelButtons,
    controlBarButtons
} from "./control-bar-buttons";
import { Router } from "@angular/router";
import { UIService } from "../shared/services/ui.service";
import { PopUpMenuComponent } from "../popup-menu/popup-menu.component";
import Point from "../shared/S4Point";
import {
    addInset,
    toggleAddInset,
    exitCropping
} from "../cdt-diagram/cdt-diagram-cropping";
import { CRUDService } from "../shared/services/crud.service";
import { CrashReportService } from "../shared/services/crash-report.service";
import { actionManager } from "../shared/Action";
import { GroupSelectionBuilder } from "../classes/GroupSelection";
import { QueryStringService } from "../shared/services/query-string.service";

@Component({
    selector: "control-bar",
    templateUrl: "./control-bar.component.html",
    styleUrls: ["./control-bar.component.scss"]
})
export class ControlBarComponent implements AfterContentInit {
    @ViewChild("infoPopup") infoPopup: PopUpMenuComponent;
    @ViewChild("bugReport") bugPopup: PopUpMenuComponent;
    @ViewChild("help") helpPopup: PopUpMenuComponent;

    grayscaleToggled = false;

    get buttons() {
        return !this.isCropping ? controlBarButtons : confirmOrCancelButtons;
    }

    get isRepositioning() {
        return this.ui.isRepositioning;
    }

    // get savingDisabled() {
    //     return noSaving;
    // }

    get isCropping() {
        return this.ui.isCropping;
    }
    constructor(
        private router: Router,
        private ui: UIService,
        private crud: CRUDService,
        private report: CrashReportService,
        private diagram: DiagramService,
        private ids: QueryStringService
    ) {}

    ngAfterContentInit(): void {}
    get isMobileOrTablet() {
        return this.ui.isMobileOrTablet;
    }

    get popupPosition(): Point {
        let h = window.innerHeight,
            w = window.innerWidth,
            x = w / 2,
            y = h / 2.1;
        return new Point(x, y);
    }
    async buttonClick(e: MouseEvent, btn: any) {
        e.stopPropagation();

        switch (btn) {
            case "Data":
                this.infoPopup.open(this.popupPosition, "center");
                break;
            case "Reset":
                await this.diagram.resetDiagram();
                break;
            case "Select":
                GroupSelectionBuilder.toggleLasso();
                break;
            case "Select All":
                GroupSelectionBuilder.selectAll();
                break;
            case "Undo":
                actionManager.undo();
                break;
            case "Redo":
                actionManager.redo();
                break;
            case "Export":
                DiagramObject.deselect();
                this.ui.isCropping = true;
                this.crud.downloadDiagramImageJpeg();
                // setTimeout(async () => {
                //     this.uiService.isCropping = true;
                //     await saveDiagramImage(this.uiService);
                // });
                break;
            case "Report":
                this.bugPopup.open(this.popupPosition, "center");
                break;
            case "Help":
                this.helpPopup.open(this.popupPosition, "center");
                break;
            case "Done":
                exitCropping(true);
                break;
            case "Back":
                exitCropping(false);
                break;
            case "Inset":
                toggleAddInset();
                break;
            case "Finish":
                DiagramObject.deselect();
                if (!(await this.report.validateDiagram())) return;

                this.ui.isCropping = true;
                const saveResult = await this.crud.saveDiagramImage();
                if (saveResult && saveResult != "canceled") {
                    if (environment.production) {
                        const choice = await promptBox(
                            "Success",
                            `The diagram image was saved and is ready to be imported into the crash form!
                            You may close the browser and return to the crash form.`,
                            ["Make Edits", "Exit App"]
                        );

                        if (choice == "Exit App") {
                            window.close();
                        }
                    } else {
                        this.router.navigateByUrl(
                            `/demo-page?id=${this.ids.artifactId}&vi=${this.ids.vendorId}`
                        );
                    }
                } else {
                    if (!saveResult) {
                        await promptBox(
                            "Error",
                            "The diagram image could not be saved. Please check your network connection or contact support"
                        );
                    }
                }
                break;
        }
    }
}
