import { idToIconNameMap } from "./icon";
import {
    DiffWorkerReq,
    DiffWorkerReqType,
    DiffWorkerRes,
    DiffWorkerResType,
    EncodedJSON,
    base64ToBlob,
    pack,
    unpack
} from "./models/diff-worker.models";
import * as paper from "paper";
import { DiagramElement } from "../classes/DiagramElement";
import { getNestedProperty, pause } from "./helpers";
import { DiagramService } from "./services/diagram.service";
import {
    disablePage,
    enablePage,
    finishMajorDraw,
    startMajorDraw
} from "../page-disabler/disable-enable-page";
import {
    disableButton,
    enableButton
} from "../control-bar/control-bar-buttons";
import { DiagramObject } from "../classes/DiagramObject";
import {
    GeoServiceLocation,
    GeolocationService
} from "./services/geolocation.service";
import { setLatLng } from "./diagram-map";
import { LatLng } from "leaflet";
import { environment } from "src/environments/environment";
import { CRUDService } from "./services/crud.service";
// import { compressSync, strToU8 } from "fflate";

/*
action props:
name: "button click"
targetName: "Select All"

addEventListeners!

UI interaction
    button clicks
    selecting/deselect an object?

    map stuff?
        panning
        zooming


diagram transformations
    adding an object
    translating an object
    rotating an object


translationRoutine(point: Point) {

}

this.translateAction = new Action("translate", {
    startRoutine: this.translateRoutine;
    endRoutine
})

someObject.onmousedown = (e: MouseEvent) => {
    this.translateAction.start(e.point);
}

someObject.onmousedown = (e: MouseEvent) => {

}
*/

export interface ActionRecord {
    className: string;
    encodedJson: EncodedJSON;
}

export interface ActionParams {
    name?: string;
}

// function decode(code: string) {
//     const binString = atob(code);
//     return new TextDecoder().decode(
//         Uint8Array.from(binString, (m) => m.codePointAt(0)!)
//     );
// }

// function encode(text: string) {
//     const bytes = new TextEncoder().encode(text);
//     const binString = String.fromCodePoint(...bytes);
//     return btoa(binString);
// }

export class Action {
    static readonly fixedName?: string = undefined;
    name: string;
    protected _time: number;
    protected _state: "done" | "undone" = "done";

    get time() {
        return this._time;
    }

    private set time(time: number) {
        this._time = time;
    }

    constructor();
    constructor(params: ActionParams);
    constructor(record: ActionRecord);
    constructor(param?: ActionParams | ActionRecord) {
        const fixedName = (this.constructor as typeof Action).fixedName;

        if (param) {
            if (!Object.hasOwn(param, "encodedJson")) {
                if ((param as ActionParams).name && fixedName) {
                    throw new Error(
                        "Cannot supply a name for an Action class with a fixed name"
                    );
                }
                Object.assign(this, param);
            } else {
                this.import((param as ActionRecord).encodedJson);
            }
        }

        if (fixedName) this.name = fixedName;
    }

    record(): void {
        this._time = Date.now();
    }

    protected replacer(key: string, value: any) {
        return value;
    }

    protected reviver(key: string, value: any) {
        return value;
    }

    import(encodedJson: EncodedJSON) {
        const obj = EncodedJSON.decode(encodedJson, this.reviver);
        Object.assign(this, obj);
    }

    export(): EncodedJSON {
        const record: ActionRecord = {
            className: this.constructor.name,
            encodedJson: EncodedJSON.encode(this, this.replacer)
        };
        return EncodedJSON.encode(record);
    }
}

class UndoableAction extends Action {
    private get redoable() {
        return this._state == "undone";
    }

    private get undoable() {
        return this._state == "done";
    }

    async redo() {
        if (!this.redoable) return false;
        this._state = "done";
        return this._redo();
    }

    async undo() {
        if (!this.undoable) return false;
        this._state = "undone";
        return this._undo();
    }

    protected async _redo() {
        return false;
    }

    protected async _undo() {
        return false;
    }
}

function Transient<BaseAction extends new (...args: any[]) => Action>(
    Base: BaseAction
) {
    return class extends Base {
        _endTime: number;

        get startTime() {
            return this._time;
        }

        private set startTime(time: number) {
            this._time = time;
        }

        get endTime() {
            return this._endTime;
        }

        private set endTime(time: number) {
            this._endTime = time;
        }

        get duration() {
            return this._endTime - this.startTime;
        }

        start() {
            this.record();
        }

        stop() {
            this.endTime = Date.now();
        }
    };
}

export interface DiagramActionParams extends ActionParams {
    isLocked?: boolean
}

export class DiagramAction extends Transient(UndoableAction) {
    private static beforeAction: string = "";
    private static currentState: string = "";
    private static diffWorker = new Worker(
        new URL("./workers/action-diff.worker", import.meta.url)
    );
    private static pendingPatches = new Map<number, DiagramAction>();
    private static patchIds = {
        num: 0,
        next() {
            return this.num++;
        }
    };
    private static actionManager: S4DiagramActionManager;
    private static restoreResolver: () => void;
    private static _isReady = false;

    // static stopActionsOtherThan(actionNames: string | string[]) {
    //     if (!Array.isArray(actionNames)) {
    //         actionNames = [actionNames];
    //     }
    //     if (
    //         this.currentAction &&
    //         !actionNames.includes(this.currentAction.name)
    //     ) {
    //         this.currentAction.stopRecording();
    //         return true;
    //     }

    //     return false;
    // }

    static prepareForNewAction() {
        if (this.currentAction) {
            if (!this.currentAction.isLocked) {
                this.currentAction.stopRecording();
                return true;
            } else {
                return false;
            }
        }

        return true;
    }

    static get isReady() {
        return this._isReady;
    }

    static getCurrentState() {
        return this.currentState;
    }

    static clearPending() {
        this.pendingPatches.clear();
    }

    static init(state: string, actionManager: S4DiagramActionManager) {
        this.currentState = state;
        this.actionManager = actionManager;

        this.diffWorker.onmessage = async (ev: MessageEvent<DiffWorkerRes>) => {
            const { type, data } = ev.data;

            switch (type) {
                case DiffWorkerResType.LOG:
                    if (Array.isArray(data)) console.log(...data);
                    else console.log(data);
                    break;
                case DiffWorkerResType.PATCH_OBJ:
                    this.assignPatch(data as [number, Uint8Array]);
                    break;
                case DiffWorkerResType.RESTORE:
                    await this.restore(data as string);
                    this.restoreResolver();
            }
        };

        this._isReady = true;
    }

    private static assignPatch([id, patch]: [number, Uint8Array]) {
        const instance = this.pendingPatches.get(id);
        if (instance) {
            instance.patch = patch;
            instance.patchQueueId = undefined;
            this.actionManager.commitAction(instance);
            this.pendingPatches.delete(id);

            if (this.pendingPatches.size === 0) {
                this.actionManager.isBusy = false;
            }
            // blobToBase64(actionManager.export()).then((data: string) => {
            //     localStorage.setItem("action_data", data);
            // });
        }
    }

    private static requestPatch(diagramAction: DiagramAction) {
        this.actionManager.isBusy = true;
        const id = (diagramAction.patchQueueId = DiagramAction.patchIds.next());
        this.pendingPatches.set(id, diagramAction);
        const msg: DiffWorkerReq = {
            type: DiffWorkerReqType.CREATE_PATCH,
            data: [id, this.beforeAction, this.currentState]
        };
        this.diffWorker.postMessage(msg);
    }

    private static async requestUndo(patch: Uint8Array) {
        disablePage();
        let state = this.currentState;
        const msg: DiffWorkerReq = {
            type: DiffWorkerReqType.UNDO,
            data: [state, patch]
        };
        this.diffWorker.postMessage(msg);
        await new Promise<void>((res) => {
            this.restoreResolver = res;
        });
    }

    private static async requestRedo(patch: Uint8Array) {
        disablePage();
        const msg: DiffWorkerReq = {
            type: DiffWorkerReqType.REDO,
            data: [this.currentState, patch]
        };
        this.diffWorker.postMessage(msg);
        await new Promise<void>((res) => {
            this.restoreResolver = res;
        });
    }

    protected static async restore(diagramJson: string) {
        startMajorDraw();
        paper.projects[0].clear();
        paper.projects[0].importJSON(diagramJson);
        await DiagramService.reestablish();
        finishMajorDraw();
        this.currentState = diagramJson;
        enablePage();
    }

    public static currentAction: DiagramAction | undefined = undefined;

    static get isRecording() {
        return this.currentAction ? true : false;
    }

    private patch: Uint8Array;
    private patchQueueId: number | undefined;
    public isLocked: boolean;

    constructor(params: DiagramActionParams);
    constructor(record: ActionRecord);
    constructor(param: DiagramActionParams | ActionRecord) {
        super(param as any);
        if (!DiagramAction.isReady) return undefined!;
    }

    record() {
        this.startRecording();
    }

    startRecording(): void {
        if (!DiagramAction.isReady) {
            // console.trace("DiagramAction isn't ready yet!");
            // console.log(this.name);
            return;
        }

        if (DiagramAction.isRecording) {
            // console.log(
            //     "%cCurrently recording:",
            //     "color: blue",
            //     DiagramAction.currentAction
            // );
            // console.log("%cTrying to record:", "color: red", this);
            return;
        }

        // console.log(`%cStarting to record: ${this.name}`, "color: green");

        super.record();
        DiagramAction.currentAction = this;
    }

    cancel(): void {
        if (this.patchQueueId) {
            DiagramAction.pendingPatches.delete(this.patchQueueId);
            this.patchQueueId = undefined;
        }
        DiagramAction.currentAction = undefined;
        // console.log("Cancelling", this.name);
    }

    stopRecording(): void {
        if (!DiagramAction.isReady) {
            // console.trace("DiagramAction isn't ready yet!");
            // console.log(this.name);
            return;
        }

        // console.log(`%cStopping: ${this.name}`, "color: purple");

        DiagramAction.beforeAction = DiagramAction.currentState;
        DiagramAction.currentState = paper.projects[0].exportJSON();
        DiagramAction.currentAction = undefined;
        DiagramAction.requestPatch(this);
        this.stop();
        CRUDService.requestSave();
    }

    protected async _undo() {
        await DiagramAction.requestUndo(this.patch);
        return true;
    }

    protected async _redo() {
        await DiagramAction.requestRedo(this.patch);
        return true;
    }

    protected replacer(key: string, value: any) {
        if (key === "patch") {
            return Array.from(value);
        }

        return super.replacer(key, value);
    }

    protected reviver(key: string, value: any) {
        if (key === "patch") {
            return new Uint8Array(value);
        }

        return super.reviver(key, value);
    }
}

export class RelocateAction extends DiagramAction {
    priorLocation: GeoServiceLocation;
    resultingLocation: GeoServiceLocation;

    startRecording(): void {
        this.priorLocation = GeolocationService.geoServiceData.Location;
        super.startRecording();
    }

    stopRecording(): void {
        this.resultingLocation = GeolocationService.geoServiceData.Location;
        super.stopRecording();
    }

    protected async _undo() {
        await GeolocationService.updateLocationRecord(this.priorLocation);
        setLatLng(
            new LatLng(
                this.priorLocation.Latitude,
                this.priorLocation.Longitude
            )
        );
        await super._undo();
        return true;
    }

    protected async _redo() {
        await GeolocationService.updateLocationRecord(this.resultingLocation);
        setLatLng(
            new LatLng(
                this.resultingLocation.Latitude,
                this.resultingLocation.Longitude
            )
        );
        await super._redo();
        return true;
    }

    cancel(): void {
        setLatLng(
            new LatLng(
                this.priorLocation.Latitude,
                this.priorLocation.Longitude
            )
        );
        DiagramAction.restore(DiagramAction.getCurrentState());
        super.cancel();
    }
}

export interface DiagramElementActionParams extends DiagramActionParams {
    elementRef?: DiagramElement;
}

export class DiagramElementAction extends DiagramAction {
    private elementDetails: {
        className: string;
        label?: string;
        icon?: string;
    };

    private _elemCache: DiagramElement | undefined;
    private _elemId: string;

    constructor(params: DiagramElementActionParams);
    constructor(record: ActionRecord);
    constructor(param: DiagramElementActionParams | ActionRecord) {
        super(param as any);


        if (param["elementRef"]) {
            this._elemCache = param["elementRef"];
            this.populateDetails();
        }
    }

    get elementRef(): DiagramElement {
        if (!this._elemCache) {
            this._elemCache = DiagramService.getById(this._elemId);
        }

        return this._elemCache;
    }

    protected set elementRef(element: DiagramElement | undefined) {
        this._elemCache = element;
    }

    stopRecording(elementRef?: DiagramElement): void {
        if (elementRef) {
            this.elementRef = elementRef;
            this.populateDetails();
        }
        super.stopRecording();
    }

    private populateDetails() {
        this._elemId = this.elementRef.id;

        this.elementDetails = {
            className: this.elementRef.className
        };

        if ("labelText" in this.elementRef) {
            this.elementDetails.label = this.elementRef["labelText"] as string;
        }

        if ("icon" in this.elementRef) {
            this.elementDetails.icon = idToIconNameMap[
                this.elementRef["icon"] as string
            ] as string;
        }
    }

    protected replacer(key: string, value: any) {
        if (key === "_elemCache") {
            return undefined;
        }

        return super.replacer(key, value);
    }
}

interface PropTrackingParams<PropType> extends DiagramElementActionParams {
    propKey: string;
    propComparator?: (a: PropType, b: PropType) => boolean;
}

export class PropTrackingAction<PropType> extends DiagramElementAction {
    private propKey: string;
    private propComparator?: (a: PropType, b: PropType) => boolean;
    private propBefore: PropType;
    private propAfter: PropType;

    get propHasChanged() {
        const after = this.propAfter
            ? this.propAfter
            : getNestedProperty(this.elementRef, this.propKey)[0];
        return !(this.propComparator
            ? this.propComparator(
                  this.propBefore,
                  this.propAfter ? this.propAfter : after
              )
            : this.propBefore === after);
    }

    constructor(params: PropTrackingParams<PropType>);
    constructor(record: ActionRecord);
    constructor(param: PropTrackingParams<PropType> | ActionRecord) {
        super(param as any);
    }

    startRecording(): void {
        this.propBefore = getNestedProperty(this.elementRef, this.propKey)[0];
        if (
            typeof this.propBefore === "object" &&
            this.propBefore &&
            "clone" in this.propBefore
        ) {
            this.propBefore = (this.propBefore["clone"] as Function)();
        }
        super.startRecording();
    }

    stopRecording(): void {
        this.propAfter = getNestedProperty(this.elementRef, this.propKey)[0];
        super.stopRecording();
    }
}

class UndoAction extends Action {
    static readonly fixedName = "undo";
}

class RedoAction extends Action {
    static readonly fixedName = "redo";
}

export interface ActionManagerData {
    readonly actionStackJson: EncodedJSON[];
    readonly frameIndex: number;
    readonly undoLength: number;
    readonly numUndoables: number;
    readonly rootFrame: number;
}

interface ActionFrameExport {
    encodedActionRecord: EncodedJSON;
    prev: number;
    next: number;
}

class ActionFrame {
    constructor(
        public action: Action,
        public prev: number | undefined = undefined,
        public next: number | undefined = undefined
    ) {}

    export() {
        return EncodedJSON.encode({
            encodedActionRecord: this.action.export(),
            prev: this.prev,
            next: this.next
        } as ActionFrameExport);
    }
}

export class ActionManager {
    protected actionStack: ActionFrame[] = [];
    private registeredActions = new Map<string, new (...args: any) => Action>();
    private frameIndex = -1;
    private rootFrame = -1;
    private numUndoables = 0;
    private undoLength = 0;
    public isBusy = false;

    get canUndo() {
        return !this.isBusy && this.numUndoables > 0;
    }

    get canRedo() {
        return !this.isBusy && this.undoLength > 0;
    }

    private get currentFrame(): ActionFrame | undefined {
        return this.actionStack[this.frameIndex];
    }

    private goToNext() {
        if (this.frameIndex < 0) {
            this.frameIndex = this.rootFrame;
        } else {
            this.frameIndex =
                this.currentFrame?.next ?? this.actionStack.length;
        }
        return this.currentFrame;
    }

    private goToPrev() {
        this.frameIndex = this.currentFrame?.prev ?? -1;
        return this.currentFrame;
    }

    replayInterval = 500;

    constructor() {
        this.registerActionClass(RedoAction);
        this.registerActionClass(UndoAction);
    }

    registerActionClass<A extends new (...args: any) => Action>(
        actionClass: A
    ) {
        this.registeredActions.set(
            actionClass.prototype.constructor.name,
            actionClass
        );
    }

    commitAction(action: Action) {
        const actionFrame = new ActionFrame(action);

        if (action instanceof UndoableAction) {
            ++this.numUndoables;
            this.undoLength = 0;
        }

        if (this.frameIndex < 0) {
            this.frameIndex = this.rootFrame = this.actionStack.length;
        } else if (this.currentFrame) {
            actionFrame.prev = this.frameIndex;
            if (action instanceof UndoableAction) {
                this.currentFrame.next = this.frameIndex =
                    this.actionStack.length;
            }
        }

        this.actionStack.push(actionFrame);
        // console.log(
        //     `%cCommmiting: ${action.name} | frame index: ${this.frameIndex}`,
        //     "color: orange"
        // );
    }

    private replaying = false;

    async replayActions(startFrom = 0) {
        if (this.replaying) return;
        this.replaying = true;
        await this.rewind(startFrom);

        while (this.canRedo) {
            let waitTime = 0;
            const nextFrame = this.actionStack.at(this.frameIndex + 1);
            if (nextFrame?.action instanceof DiagramAction) {
                waitTime = nextFrame.action.duration;
            }
            await pause(waitTime);
            await this.redo();
        }

        this.replaying = false;
    }

    async rewind(to = 0) {
        while (this.canUndo && this.frameIndex >= to) {
            await this.undo(false);
        }
    }

    async fastForward(to = 0) {
        while (this.canRedo && this.frameIndex <= to) {
            await this.redo(false);
        }
    }

    async undo(record = true) {
        if (!this.canUndo) return;
        this.isBusy = true;

        let frame = this.currentFrame;
        let undone = false;

        while (frame) {
            if (frame.action instanceof UndoableAction) {
                // console.log(
                //     "Undoing:",
                //     frame.action.name,
                //     "index:",
                //     this.frameIndex
                // );
                undone = await frame.action.undo();
            }

            frame = this.goToPrev();

            if (undone || this.frameIndex === 0) {
                break;
            }
        }

        if (undone) {
            --this.numUndoables;
            ++this.undoLength;

            if (record) {
                const undoFrame = new ActionFrame(
                    new UndoAction(),
                    this.frameIndex >= 0 ? this.frameIndex : undefined
                );

                this.actionStack.push(undoFrame);
            }
        }

        this.isBusy = false;
    }

    async redo(record = true) {
        if (!this.canRedo) return;
        this.isBusy = true;

        let frame = this.goToNext();
        let redone = false;

        while (frame) {
            if (frame.action instanceof UndoableAction) {
                // console.log(
                //     "Redoing:",
                //     frame.action.name,
                //     "index:",
                //     this.frameIndex
                // );
                redone = await frame.action.redo();
            }

            if (redone) {
                break;
            }

            frame = this.goToNext();
        }

        if (redone) {
            ++this.numUndoables;
            --this.undoLength;

            if (record) {
                const redoFrame = new ActionFrame(
                    new RedoAction(),
                    this.frameIndex
                );

                this.actionStack.push(redoFrame);
            }
        }

        this.isBusy = false;
    }

    clear() {
        this.actionStack = [];
        this.frameIndex = 0;
        this.numUndoables = 0;
        this.undoLength = 0;
    }

    protected _getExportData(): ActionManagerData {
        const frames = this.actionStack.map((a) => a.export());
        return {
            actionStackJson: frames,
            frameIndex: this.frameIndex,
            undoLength: this.undoLength,
            numUndoables: this.numUndoables,
            rootFrame: this.rootFrame
        };
    }

    export(): string | Blob {
        return JSON.stringify(this._getExportData());
    }

    import(data: ActionManagerData) {
        this.frameIndex = data.frameIndex;
        this.numUndoables = data.numUndoables;
        this.undoLength = data.undoLength;
        this.rootFrame = data.rootFrame;

        for (const encodedJson of data.actionStackJson) {
            const frame = EncodedJSON.decode(encodedJson) as ActionFrameExport;
            const actionRecord = EncodedJSON.decode(
                frame.encodedActionRecord
            ) as ActionRecord;
            const actionConstr = this.registeredActions.get(
                actionRecord.className
            );
            if (!actionConstr) {
                throw new Error(
                    `Trying to load an Action that has not been registered: ${actionRecord.className}`
                );
            }
            this.actionStack.push(
                new ActionFrame(
                    new actionConstr(actionRecord),
                    frame.prev,
                    frame.next
                )
            );
        }
    }
}

interface S4DiagramActionData extends ActionManagerData {
    readonly diagramStateJson: string;
}

// var comparison = new Array<Uint8Array>();

export class S4DiagramActionManager extends ActionManager {
    commitAction(action: Action): void {
        super.commitAction(action);
        // comparison.push(compressString(paper.projects[0].exportJSON()));

        if (action instanceof UndoableAction) {
            enableButton("Undo");
            disableButton("Redo");
        }
    }

    // compare() {
    //     console.log(
    //         `%cExport Stack: ${
    //             comparison.reduce(
    //                 (sum: number, v: Uint8Array) => sum + v.byteLength,
    //                 0
    //             ) / 1000
    //         } %c| %cAction Data: ${
    //             this.actionStack.reduce((sum: number, v: ActionFrame) => {
    //                 if (v.action instanceof DiagramAction)
    //                     return sum + v.action["patch"].byteLength;
    //                 else return sum;
    //             }, 0) / 1000
    //         }`,
    //         "color: red",
    //         "color: purple",
    //         "color: green"
    //     );
    // }

    async undo() {
        disableButton("Undo");
        await super.undo();

        if (this.canUndo) {
            enableButton("Undo");
        }

        if (this.canRedo) {
            enableButton("Redo");
        }
    }

    async redo() {
        disableButton("Redo");
        await super.redo();

        if (this.canRedo) {
            enableButton("Redo");
        }

        if (this.canUndo) {
            enableButton("Undo");
        }
    }

    export(): Blob {
        const data: S4DiagramActionData = {
            ...super._getExportData(),
            diagramStateJson: DiagramAction.getCurrentState()
        };

        return pack(data);
    }

    async import(data: any) {
        data = await unpack(data);
        super.import(data as ActionManagerData);
        DiagramAction.init(data.diagramStateJson, actionManager);
        if (this.canRedo) {
            enableButton("Redo");
        }
        if (this.canUndo) {
            enableButton("Undo");
        }
    }

    clear(): void {
        super.clear();
        DiagramAction.clearPending();
        localStorage.removeItem("action_data");
        DiagramAction.init(paper.projects[0].exportJSON(), actionManager);
    }

    get userHasNotMadeAnyEdits() {
        if (!this.canUndo || this.diagramWasJustReset) {
            return true;
        }

        return false;
    }

    get diagramWasJustReset() {
        return this.actionStack.at(-1)?.action.name === "reset";
    }
}

export var actionManager = new S4DiagramActionManager();

export async function setUpActionManager() {
    actionManager.registerActionClass(DiagramAction);
    actionManager.registerActionClass(DiagramElementAction);
    actionManager.registerActionClass(PropTrackingAction);
    actionManager.registerActionClass(RelocateAction);

    const data = localStorage.getItem("action_data");
    if (data) {
        try {
            await actionManager.import(base64ToBlob(data));
        } catch (error) {
            console.error(error);
            actionManager.clear();
        }
    } else {
        DiagramAction.init(paper.projects[0].exportJSON(), actionManager);
    }

    if (!environment.production) {
        Object.defineProperties(window, {
            paper: { value: paper },
            am: { value: actionManager },
            DA: { value: DiagramAction },
            DiagramService: { value: DiagramService },
            DiagramObject: { value: DiagramObject }
        });
    }
}
