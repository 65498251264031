import * as paper from "paper";
import {
    clearCursor,
    Cursor,
    lockCursor,
    setCursor,
    unlockCursor
} from "../shared/cursor";
import { InsetData, UIService } from "../shared/services/ui.service";
import {
    disablePage,
    enablePage,
    promptBox
} from "../page-disabler/disable-enable-page";
// import { loadSVGFromFile } from "./cdt-diagram";
import {
    disableMapDragging,
    enableMapDragging,
    getDiagramZoomScale,
    goHome,
    leafletMap,
    projectPointToLatLng
} from "../shared/diagram-map";
import { DiagramObject } from "../classes/DiagramObject";
import {
    createMapTetheredLayer,
    DiagramLayer,
    getLayer,
    lockLayers,
    unlockLayers
} from "../shared/layer";
import {
    getDiagramBounds,
    getDiagramElementBounds,
    getInverseScaling,
    pause
} from "../shared/helpers";
import { DiagramService } from "../shared/services/diagram.service";
import { CRUDService } from "../shared/services/crud.service";
import Point from "../shared/S4Point";
import { InsetToolComponent } from "../inset-tool/inset-tool.component";
import { LatLng } from "leaflet";
import { toggleButton } from "../control-bar/control-bar-buttons";

export const defaultCropPadding = 250;
export const resizeNodeRadius = 8;

const BOUNDS_PADDING = 25;
const PAD_SIDE = 10;
const PAD_BOTTOM = 30;
const PAD_TOP = 42;
const INSET_GAP = 6;

let minCropBounds: paper.Rectangle;
let diagramBounds: paper.Rectangle;
let boundsAreDirty: boolean = true;
let cropArea: paper.Path;
let grayWall: paper.Path;
let grayOut: paper.PathItem;
let resizeNodes: paper.Group;
let northArrow: paper.Group;
let notDrawnToScaleLegend: paper.Group;
let s4Watermark: paper.Item;
let ui: UIService;
let resolver: Function;
let rejector: Function;
let selectedBounds: paper.Rectangle;

function loadMiscIcon(name: string, insert = false) {
    const iconSet = document.querySelector(`#misc-ui`);
    const icon = iconSet?.querySelector(
        `[data-icon-id="${name}"]`
    ) as SVGElement;
    return paper.projects[0].importSVG(icon, { insert: insert });
}

export async function getCroppingRect(
    uiService: UIService
): Promise<paper.Rectangle> {
    uiService.isCropping = true;
    CRUDService.disableSaving();

    // disable anything distracting
    disablePage();
    DiagramObject.deselect();
    lockLayers(DiagramLayer.cropping);

    ui = uiService;
    ui.isDataGridCollapsed = true;
    ui.sidePanelOpen = false;
    ui.gridDisabled = true;
    ui.controlButtonsVisible = false;

    // center the user on the diagram object
    leafletMap.once("moveend", continueSetup);
    goHome({ ...uiService.padding }, getDiagramBounds(defaultCropPadding));

    return new Promise<paper.Rectangle>((resolve, reject) => {
        resolver = resolve;
        rejector = reject;
    });
}

function continueSetup() {
    // create the cropping UI
    const northArrowShape = loadMiscIcon("north arrow");
    northArrowShape.bounds = new paper.Rectangle([0, 0], [25, 33]);
    const bgColor = new paper.Color(255, 255, 255, 0.65);
    let bg = new paper.Path.Rectangle({
        size: northArrowShape.bounds.expand(8),
        fillColor: bgColor,
        radius: new paper.Size(5, 5)
    });
    bg.position = northArrowShape.position;
    northArrow = new paper.Group([bg, northArrowShape]);
    northArrow.applyMatrix = false;
    northArrow.pivot = bg.bounds.topRight;
    northArrow.data.isCroppingUi = true;
    northArrow.remove();

    // "Not drawn to scale"
    const notDrawnToScaleText = new paper.PointText({
        content:
            "Disclaimer: background imagery does not depict time of crash. Diagram not to scale.",
        fontWeight: "bold"
    });
    bg = new paper.Path.Rectangle({
        size: notDrawnToScaleText.bounds.size.add(5),
        fillColor: bgColor,
        radius: new paper.Size(5, 5)
    });
    bg.position = notDrawnToScaleText.position;
    notDrawnToScaleLegend = new paper.Group([bg, notDrawnToScaleText]);
    notDrawnToScaleLegend.data.isCroppingUi = true;
    notDrawnToScaleLegend.remove();

    // S4 Watermark
    s4Watermark = loadMiscIcon("s4icon");
    s4Watermark.opacity = 0.25;
    s4Watermark.data.isCroppingUi = true;

    northArrow.data.thing = "legend";
    notDrawnToScaleLegend.data.thing = "legend";
    s4Watermark.data.thing = "legend";

    // establish the bounds
    sizeLegends();
    boundsAreDirty = true;
    calculateBounds();
    notDrawnToScaleLegend.bounds.bottomCenter = diagramBounds.bottomCenter;
    minCropBounds = getMinBounds();
    getLayer(DiagramLayer.cropping).activate();

    setUpCroppingUI();
    repositionInsets();

    // add necessary event listeners
    window.addEventListener("resize", onWindowResize);
    leafletMap.on("move", mapMovedUpdate);
    leafletMap.on("zoomend", zoomEndUpdate);

    // now we're ready to allow the user to choose the cropping area
    window.addEventListener("keydown", cancelOnEscapeKey);
    enablePage();
}

function cancelOnEscapeKey(e: KeyboardEvent) {
    if (e.key == "Escape") {
        exitCropping(false);
    }
}

export async function exitCropping(accepted: boolean) {
    window.clearTimeout(windowResizeTimeout);
    window.clearTimeout(windowResizeTimeout2);
    resizingWindow = false;
    disablePage();

    if (accepted) {
        if (!paper.projects[0].view.bounds.contains(cropArea.bounds)) {
            // Try centering
            await new Promise<void>((res) => {
                leafletMap.panTo(projectPointToLatLng(cropArea.bounds.center), {
                    animate: false
                });

                setTimeout(() => {
                    res();
                }, 500);
            });

            if (!paper.projects[0].view.bounds.contains(cropArea.bounds)) {
                await helpUserFitBounds();

                return;
            }
        }

        // save the extent
        saveBounds();
    }

    window.removeEventListener("keydown", cancelOnEscapeKey);
    removeCroppingUIAndListeners();

    // the legends must be added to a captured layered, and then removed in the image processing function
    getLayer(DiagramLayer.groupSelection).addChildren([
        northArrow,
        notDrawnToScaleLegend,
        s4Watermark
    ]);

    setTimeout(() => {
        getLayer(DiagramLayer.main).activate();
        if (accepted) {
            resolver(
                new paper.Rectangle(
                    paper.projects[0].view.projectToView(
                        cropArea.bounds.topLeft
                    ),
                    paper.projects[0].view.projectToView(
                        cropArea.bounds.bottomRight
                    )
                )
            );
        } else {
            rejector("canceled");
        }

        enablePage();
    }, 50);
}

function saveBounds() {
    const crashPoint = DiagramService.crashPointObject.position;
    getLayer(DiagramLayer.main).data.savedCropArea = {
        x: crashPoint.x - cropArea.bounds.left,
        y: crashPoint.y - cropArea.bounds.top,
        width: cropArea.bounds.width,
        height: cropArea.bounds.height,
        zoom: leafletMap.getZoom()
    };
}

async function helpUserFitBounds() {
    // let offerFullscreen = false;

    // if (
    //     document.fullscreenEnabled &&
    //     new paper.Rectangle(
    //         [0, 0],
    //         [screen.availWidth, screen.availHeight]
    //     ).contains(cropArea.bounds)
    // ) {
    //     const diagram = document.getElementById("map");
    //     await diagram?.requestFullscreen({ navigationUI: "hide" });
    //     await exitCropping(true);
    //     document.exitFullscreen();
    // }

    await promptBox(
        "Diagram Bounds Outside Window",
        `The diagram's bounds must be fully contained in the window.
        Please maximize the window, then click "Continue." S4 Diagram will attempt to fit the bounds.
        Afterwards, make any adjustments and click "Done" again to finish.`,
        ["Continue"]
    );

    goHome(ui.padding, getDiagramBounds());
    enablePage();
}

function removeCroppingUIAndListeners() {
    getLayer(DiagramLayer.cropping).removeChildren();
    window.removeEventListener("resize", onWindowResize);
    leafletMap.off("move", mapMovedUpdate);
    leafletMap.off("zoomend", zoomEndUpdate);
    ui.gridDisabled = false;
    if (ui.helpBar.isShown()) {
        ui.helpBar.close();
        setTimeout(() => {
            ui.controlButtonsVisible = true;
        }, 500);
    } else {
        ui.controlButtonsVisible = true;
    }
    getLayer(DiagramLayer.main).activate();
}

function addResizeNode(rect: paper.Path, pos: string): paper.Path {
    const zoomScale = getDiagramZoomScale();
    const rectBounds = rect.bounds;
    const node = new paper.Path.Circle({
        center: rectBounds[pos],
        radius: resizeNodeRadius / zoomScale,
        fillColor: "#fefefe",
        strokeWidth: 1 / zoomScale,
        name: pos,
        data: { thing: "resize node", position: pos },
        applyMatrix: false
    });

    const diagonalOffset = 2 / zoomScale;
    const offset = diagonalOffset / 2;

    switch (pos) {
        case "topLeft":
            node.position.x += diagonalOffset;
            node.position.y += diagonalOffset;
            node.data.offset = new paper.Point(diagonalOffset, diagonalOffset);
            node.data.controls = ["top", "left"];
            node.data.cursor = Cursor.NWSE_Resize;
            break;
        case "topRight":
            node.position.x -= diagonalOffset;
            node.position.y += diagonalOffset;
            node.data.offset = new paper.Point(-diagonalOffset, diagonalOffset);
            node.data.controls = ["top", "right"];
            node.data.cursor = Cursor.NESW_Resize;
            break;
        case "bottomLeft":
            node.position.x += diagonalOffset;
            node.position.y -= diagonalOffset;
            node.data.offset = new paper.Point(diagonalOffset, -diagonalOffset);
            node.data.controls = ["bottom", "left"];
            node.data.cursor = Cursor.NESW_Resize;
            break;
        case "bottomRight":
            node.position.x -= diagonalOffset;
            node.position.y -= diagonalOffset;
            node.data.offset = new paper.Point(
                -diagonalOffset,
                -diagonalOffset
            );
            node.data.controls = ["bottom", "right"];
            node.data.cursor = Cursor.NWSE_Resize;
            break;
        case "leftCenter":
            node.position.x += offset;
            node.data.offset = new paper.Point(offset, 0);
            node.data.controls = ["left"];
            node.data.cursor = Cursor.EW_Resize;
            break;
        case "topCenter":
            node.position.y += offset;
            node.data.offset = new paper.Point(0, offset);
            node.data.controls = ["top"];
            node.data.cursor = Cursor.NS_Resize;
            break;
        case "rightCenter":
            node.position.x -= offset;
            node.data.offset = new paper.Point(-offset, 0);
            node.data.controls = ["right"];
            node.data.cursor = Cursor.EW_Resize;
            break;
        case "bottomCenter":
            node.position.y -= offset;
            node.data.offset = new paper.Point(0, -offset);
            node.data.controls = ["bottom"];
            node.data.cursor = Cursor.NS_Resize;
            break;
    }
    node.onMouseEnter = () => {
        setCursor(node.data.cursor);
        node.scale(1.5);
        node.strokeColor = new paper.Color("#fefefe");
        node.fillColor = new paper.Color("#3d8aff");
    };
    node.onMouseLeave = () => {
        clearCursor();
        node.scaling = new paper.Point(1, 1);
        node.strokeColor = null;
        node.fillColor = new paper.Color("#fefefe");
    };
    node.onMouseDrag = resizeNodeDrag;
    node.onMouseDown = () => {
        lockCursor();
        leafletMap.dragging.disable();
    };
    node.onMouseUp = (e: paper.MouseEvent) => {
        unlockCursor();
        if (!node.contains(e.point)) clearCursor();
        leafletMap.dragging.enable();
    };

    return node;
}

function getPaddedDiagramBounds() {
    return diagramBounds.expand(BOUNDS_PADDING);
}

function getMinSides() {
    const paddedDiagramBounds = getPaddedDiagramBounds();

    const minBottom =
        paddedDiagramBounds.bottom + notDrawnToScaleLegend.bounds.height + 2;
    const minLeft = Math.min(
        Math.min(notDrawnToScaleLegend.bounds.left, paddedDiagramBounds.left),
        paddedDiagramBounds.left - s4Watermark.bounds.width - 2
    );
    const minRight = Math.max(
        Math.max(notDrawnToScaleLegend.bounds.right, paddedDiagramBounds.right),
        paddedDiagramBounds.right + northArrow.bounds.width + 2
    );
    const minTop = Math.min(
        paddedDiagramBounds.top,
        paddedDiagramBounds.top - s4Watermark.bounds.height - 2
    );
    return [minLeft, minTop, minRight, minBottom];
}

function getMinBounds() {
    const [minLeft, minTop, minRight, minBottom] = getMinSides();
    return new paper.Rectangle(
        new paper.Point(minLeft, minTop),
        new paper.Point(minRight, minBottom)
    );
}

function resizeNodeDrag(e: paper.MouseEvent) {
    const oldRect = cropArea.bounds;
    calculateBounds();
    const [minLeft, minTop, minRight, minBottom] = getMinSides();

    switch (e.currentTarget.data.position) {
        case "topLeft":
            cropArea.bounds = new paper.Rectangle(
                new paper.Point(
                    Math.min(e.point.x, minLeft),
                    Math.min(e.point.y, minTop)
                ),
                oldRect.bottomRight
            );
            selectedBounds.height = cropArea.bounds.height;
            selectedBounds.width = cropArea.bounds.width;
            selectedBounds.top = cropArea.bounds.top;
            selectedBounds.left = cropArea.bounds.left;

            break;
        case "topRight":
            cropArea.bounds = new paper.Rectangle(
                new paper.Point(
                    Math.max(e.point.x, minRight),
                    Math.min(e.point.y, minTop)
                ),
                oldRect.bottomLeft
            );

            selectedBounds.height = cropArea.bounds.height;
            selectedBounds.width = cropArea.bounds.width;
            selectedBounds.top = cropArea.bounds.top;

            break;
        case "bottomLeft":
            cropArea.bounds = new paper.Rectangle(
                new paper.Point(
                    Math.min(e.point.x, minLeft),
                    Math.max(e.point.y, minBottom)
                ),
                oldRect.topRight
            );

            selectedBounds.height = cropArea.bounds.height;
            selectedBounds.width = cropArea.bounds.width;
            selectedBounds.left = cropArea.bounds.left;

            break;
        case "bottomRight":
            cropArea.bounds = new paper.Rectangle(
                new paper.Point(
                    Math.max(e.point.x, minRight),
                    Math.max(e.point.y, minBottom)
                ),
                oldRect.topLeft
            );

            selectedBounds.height = cropArea.bounds.height;
            selectedBounds.width = cropArea.bounds.width;

            break;
        case "leftCenter":
            cropArea.bounds = new paper.Rectangle(
                new paper.Point(Math.min(e.point.x, minLeft), oldRect.top),
                oldRect.bottomRight
            );

            selectedBounds.width = cropArea.bounds.width;
            selectedBounds.left = cropArea.bounds.left;

            break;
        case "topCenter":
            cropArea.bounds = new paper.Rectangle(
                new paper.Point(oldRect.left, Math.min(e.point.y, minTop)),
                oldRect.bottomRight
            );

            selectedBounds.height = cropArea.bounds.height;
            selectedBounds.top = cropArea.bounds.top;

            break;
        case "rightCenter":
            cropArea.bounds = new paper.Rectangle(
                new paper.Point(Math.max(e.point.x, minRight), oldRect.top),
                oldRect.bottomLeft
            );

            selectedBounds.width = cropArea.bounds.width;

            break;
        case "bottomCenter":
            cropArea.bounds = new paper.Rectangle(
                new paper.Point(oldRect.left, Math.max(e.point.y, minBottom)),
                oldRect.topRight
            );

            selectedBounds.height = cropArea.bounds.height;

            break;
    }

    for (const node of resizeNodes.children) {
        let w = cropArea.bounds[node.data.position].add(node.data.offset);
        node.position = w;
    }

    positionLegends();
    resizeGrayZone();
    repositionInsets();
}

function resizeGrayZone() {
    grayOut.remove();
    grayOut = grayWall.subtract(cropArea);
    grayOut.fillColor = new paper.Color(0, 0, 0, 0.75);
    grayOut.data.isCroppingUi = true;
}

async function addResizeNodeGroup() {
    resizeNodes = new paper.Group([
        addResizeNode(cropArea, "topLeft"),
        addResizeNode(cropArea, "topRight"),
        addResizeNode(cropArea, "bottomLeft"),
        addResizeNode(cropArea, "bottomRight"),
        addResizeNode(cropArea, "leftCenter"),
        addResizeNode(cropArea, "topCenter"),
        addResizeNode(cropArea, "rightCenter"),
        addResizeNode(cropArea, "bottomCenter")
    ]);
    resizeNodes.data.isCroppingUi = true;
}

function calculateBounds() {
    if (boundsAreDirty) {
        boundsAreDirty = false;
        diagramBounds = getDiagramElementBounds({ isViewBox: true });
        minCropBounds = getMinBounds();
    }
}

async function setUpCroppingUI() {
    calculateBounds();
    // const savedCropArea = getLayer(DiagramLayer.main).data.savedCropArea;
    let boundsToUse = minCropBounds;

    // if (savedCropArea) {
    //     const zoomLvl = leafletMap.getZoom();
    //     const zoomScale = leafletMap.getZoomScale(zoomLvl, savedCropArea.zoom);

    //     const crashPoint = DiagramService.crashPointObject.core.position;

    //     let savedCropAreaRect = new paper.Rectangle(
    //         crashPoint.x - savedCropArea.x * zoomScale,
    //         crashPoint.y - savedCropArea.y * zoomScale,
    //         savedCropArea.width * zoomScale,
    //         savedCropArea.height * zoomScale
    //     );

    //     // If the last used bounds still make sense, reuse it wherever it doesn't extend beyond the boundaries of the view
    //     if (savedCropAreaRect.contains(paper.projects[0].view.bounds)) {
    //         boundsToUse = savedCropAreaRect;

    //         // unless this is inside diagramBounds
    //         if (!boundsToUse.contains(minCropBounds)) {
    //             boundsToUse = minCropBounds;
    //         }
    //     }
    // }

    cropArea = new paper.Path.Rectangle(boundsToUse);
    cropArea.strokeWidth = 3;
    cropArea.strokeColor = new paper.Color("white");
    cropArea.data.thing = "crop area";
    cropArea.data.isCroppingUi = true;

    grayWall = new paper.Path.Rectangle(paper.projects[0].view.bounds);
    grayOut = grayWall.subtract(cropArea);
    grayOut.fillColor = new paper.Color(0, 0, 0, 0.75);
    grayOut.data.isCroppingUi = true;

    fixUISize();
    paper.projects[0].activeLayer.addChild(northArrow);
    paper.projects[0].activeLayer.addChild(notDrawnToScaleLegend);
    paper.projects[0].activeLayer.addChild(s4Watermark);
    // addButtons();

    selectedBounds = cropArea.bounds.clone();

    ui.helpBar.show(
        "Select the desired diagram bounds by resizing the cropping rectangle and click Done to finish.",
        5000
    );
}

let windowResizeTimeout: number;
let windowResizeTimeout2: number;
let resizingWindow = false;

function onWindowResize() {
    resizingWindow = true;
    saveBounds();
    paper.projects[0].activeLayer.removeChildren();
    window.clearTimeout(windowResizeTimeout);
    window.clearTimeout(windowResizeTimeout2);
    windowResizeTimeout = window.setTimeout(() => {
        ui.isDataGridCollapsed = true;
        goHome(ui.padding, getDiagramBounds(defaultCropPadding));

        windowResizeTimeout2 = window.setTimeout(() => {
            boundsAreDirty = true;
            setUpCroppingUI();
            resizingWindow = false;
            fixUISize();
            repositionInsets();
        }, 1000);
    }, 250);
}

function mapMovedUpdate() {
    if (!grayWall.bounds.contains(paper.projects[0].view.bounds)) {
        grayWall.bounds = grayWall.bounds.unite(paper.projects[0].view.bounds);
        resizeGrayZone();
    }

    boundsAreDirty = true;
    repositionInsets();
}

function zoomEndUpdate() {
    fixUISize();
    repositionInsets();
}

function fixUISize() {
    if (resizingWindow) {
        return;
    }

    cropArea.strokeWidth = 3 / getDiagramZoomScale();
    sizeAndPositionLegends();

    if (!cropArea.bounds.contains(notDrawnToScaleLegend.bounds)) {
        boundsAreDirty = true;
        calculateBounds();
        cropArea.bounds = minCropBounds;
        resizeGrayZone();
        positionLegends();
    }

    if (resizeNodes) resizeNodes.remove();
    addResizeNodeGroup();
}

function sizeLegends(scale?: number) {
    if (!scale) scale = getDiagramZoomScale();
    northArrow.scaling = getInverseScaling(scale);
    notDrawnToScaleLegend.fitBounds(
        new paper.Rectangle(0, 0, 525, 85).scale(1 / scale)
    );
    s4Watermark.fitBounds(new paper.Rectangle(0, 0, 40, 40).scale(1 / scale));
}

function sizeAndPositionLegends() {
    const scale = getDiagramZoomScale();
    sizeLegends(scale);
    positionLegends(scale);
}

function positionLegends(scale?: number) {
    if (!scale) scale = getDiagramZoomScale();
    northArrow.position = cropArea.bounds.topRight.add(
        new paper.Point(-5 / scale, 5 / scale)
    );
    notDrawnToScaleLegend.bounds.bottomCenter =
        cropArea.bounds.bottomCenter.add(new paper.Point(0, -5));
    s4Watermark.bounds.topLeft = cropArea.bounds.topLeft.add(
        new paper.Point(4 / scale, 4 / scale)
    );
    boundsAreDirty = true;
    calculateBounds();
}

export function toggleAddInset() {
    const toggled = toggleButton("Inset");
    if (toggled) {
        ui.helpBar.show(
            "Click to start drawing inset's bounds. Click again to accept.",
            10000
        );
        paper.view.on("mousedown", startInset);
        paper.view.element.style.cursor = "crosshair";
        window.removeEventListener("keydown", cancelOnEscapeKey);
        window.addEventListener("keydown", escKeyCancelInset);
        lockLayers();
    } else {
        cancelInset();
    }
}

function escKeyCancelInset(e: KeyboardEvent) {
    if (e.key === "Escape") {
        toggleAddInset();
    }
}

let insetRect: paper.Item;
let insetStartPoint: paper.Point;

function startInset(e: paper.MouseEvent) {
    const zoomScale = getDiagramZoomScale();
    insetStartPoint = e.point; //.subtract(InsetToolComponent.MIN_DIM / 2);
    insetRect = new paper.Path.Rectangle(
        insetStartPoint,
        e.point.add(InsetToolComponent.MIN_DIM / 2)
    );
    insetRect.strokeColor = new paper.Color("black");
    insetRect.strokeWidth = 2 / zoomScale;
    paper.view.off("mousedown", startInset);
    window.addEventListener("mousemove", drawInset);
    window.addEventListener("mousedown", finishInset);
    e.stop();
    disableMapDragging();
}

function drawInset(e: MouseEvent) {
    e.stopPropagation();
    const point = paper.projects[0].view.viewToProject(
        new paper.Point(e.x, e.y - 66)
    );
    openInsetToPoint(point);
}

function openInsetToPoint(point: paper.Point) {
    const newBounds = new paper.Rectangle(insetStartPoint, point);
    const minDim = InsetToolComponent.MIN_DIM;
    if (newBounds.width <= minDim) newBounds.width = minDim;
    else if (newBounds.width > InsetToolComponent.MAX_DIM) {
        newBounds.width = InsetToolComponent.MAX_DIM;
    }
    if (newBounds.height <= minDim) newBounds.height = minDim;
    else if (newBounds.height > InsetToolComponent.MAX_DIM) {
        newBounds.height = InsetToolComponent.MAX_DIM;
    }
    if (point.x < insetStartPoint.x) {
        newBounds.point.x = insetStartPoint.x - newBounds.width;
    }
    if (point.y < insetStartPoint.y) {
        newBounds.point.y = insetStartPoint.y - newBounds.height;
    }
    insetRect.bounds = newBounds;
}

function finishInset(e: MouseEvent) {
    window.removeEventListener("mousemove", drawInset);
    openInsetToPoint(
        paper.projects[0].view.viewToProject(new paper.Point(e.x, e.y - 66))
    );
    addInset(
        projectPointToLatLng(insetRect.bounds.center),
        insetRect.bounds.width,
        insetRect.bounds.height
    );
    toggleAddInset();
}

function cancelInset() {
    insetRect?.remove();
    window.removeEventListener("keydown", escKeyCancelInset);
    paper.view.off("mousedown", startInset);
    window.removeEventListener("mousedown", finishInset);
    window.removeEventListener("mousemove", drawInset);
    paper.view.element.style.removeProperty("cursor");
    // add back the canceling of cropping mode
    window.addEventListener("keydown", cancelOnEscapeKey);
    getLayer(DiagramLayer.cropping).locked = false;
    enableMapDragging();
}

export function addInset(latLng: LatLng, width: number, height: number) {
    ui.insets.push({
        latLng: latLng,
        point: new Point(0, 0),
        width: width,
        height: height
    });
}

function getInsetColumnsAndHeight(availHeight: number): {
    minHeight: number;
    col1: InsetData[];
    col2: InsetData[];
} {
    const insets = [...ui.insets].reverse();
    const paddedHeights = insets.map((inset) => inset.height + INSET_GAP); // Add padding to each rectangle's height
    const totalHeight = paddedHeights.reduce((acc, h) => acc + h, 0); // Total sum of all heights

    // Function to check if we can fit rectangles in two columns with height limit H
    function canFitInHeight(
        H: number
    ): { col1: InsetData[]; col2: InsetData[] } | null {
        let col1Height = 0;
        let col2Height = 0;
        const col1: InsetData[] = [];
        const col2: InsetData[] = [];
        let fillingCol2 = false;

        for (let i = 0; i < paddedHeights.length; i++) {
            const rectHeight = paddedHeights[i];
            if (!fillingCol2 && col1Height + rectHeight <= H) {
                col1.push(insets[i]); // Add to Column 1
                col1Height += rectHeight;
            } else {
                fillingCol2 = true;
                if (col2Height + rectHeight <= H) {
                    col2.push(insets[i]); // Add to Column 2
                    col2Height += rectHeight;
                } else {
                    return null; // We exceeded the height H in both columns
                }
            }
        }

        return { col1, col2 };
    }

    const noChangeColumns = canFitInHeight(availHeight);
    if (noChangeColumns) return { ...noChangeColumns, minHeight: availHeight };

    // Binary search to find the minimum possible height
    let low = Math.max(...paddedHeights); // Minimum possible height is the tallest rectangle
    let high = totalHeight; // Maximum possible height is the sum of all rectangles

    let result = {
        col1: [] as InsetData[],
        col2: [] as InsetData[],
        minHeight: high
    };

    while (low <= high) {
        const mid = Math.floor((low + high) / 2);
        const columns = canFitInHeight(mid);

        if (columns) {
            result = { ...columns, minHeight: mid }; // Found a solution, try for smaller height
            high = mid - 1;
        } else {
            low = mid + 1; // Try larger height
        }
    }

    return result;
}

export function restoreSelectedBounds() {
    if (!cropArea.bounds.equals(selectedBounds)) {
        cropArea.bounds = selectedBounds.clone();
        resizeGrayZone();
        fixUISize();
    }
}

export function repositionInsets(zoomScale?: number) {
    if (!ui.insets.length) return;

    !zoomScale && (zoomScale = getDiagramZoomScale());

    const availHeight =
        cropArea.bounds.height * zoomScale -
        (PAD_BOTTOM + PAD_TOP) -
        INSET_GAP * 2;

    const insetColumns = getInsetColumnsAndHeight(availHeight);
    const [heightNeeded, rightInsets, leftInsets] = [
        insetColumns.minHeight,
        insetColumns.col1,
        insetColumns.col2
    ];
    const widestRight = Math.max(...rightInsets.map((v) => v.width));
    const widestLeft = Math.max(...leftInsets.map((v) => v.width));

    if (availHeight < heightNeeded) {
        const diff = (heightNeeded - availHeight) / zoomScale;
        cropArea.bounds.height += diff;
        cropArea.bounds.top -= diff;
        resizeGrayZone();
        fixUISize();
    }

    let [cropBotRight, cropBotLeft] = getBotCorners();

    calculateBounds();

    const diagramBotRight = paper.projects[0].view.projectToView(
        diagramBounds.bottomRight
    );
    const diagramBotLeft = paper.projects[0].view.projectToView(
        diagramBounds.bottomLeft
    );

    const yStart = cropBotRight.y - PAD_BOTTOM;

    const point = new Point(
        Math.max(
            diagramBotRight.x + widestRight + 5,
            cropBotRight.x - PAD_SIDE
        ),
        yStart
    );

    if (cropBotRight.x - PAD_SIDE < point.x) {
        const expand = point.x - (cropBotRight.x - PAD_SIDE);
        cropArea.bounds.width += expand / zoomScale;
        resizeGrayZone();
        fixUISize();
        [cropBotRight, cropBotLeft] = getBotCorners();
        point.x = cropBotRight.x - PAD_SIDE;
    }

    const leftBoundsBuffer = diagramBotLeft.x - widestLeft - 5;

    if (leftInsets.length && cropBotLeft.x + PAD_SIDE > leftBoundsBuffer) {
        const widen = (cropBotLeft.x + PAD_SIDE - leftBoundsBuffer) / zoomScale;
        cropArea.bounds.width += widen;
        cropArea.bounds.left -= widen;
        resizeGrayZone();
        fixUISize();
        [cropBotRight, cropBotLeft] = getBotCorners();
    }

    for (const inset of rightInsets) {
        const insetPoint = new Point(
            point.x - inset.width,
            point.y - inset.height
        );
        inset.point = insetPoint;
        point.y -= inset.height + INSET_GAP;
        inset.component?.changeDetector.detectChanges();
    }

    const leftStart = new Point(
        Math.min(
            diagramBotLeft.x - widestLeft - 5,
            cropBotLeft.x + PAD_SIDE - 3
        ),
        cropBotLeft.y - PAD_BOTTOM
    );
    point.x = leftStart.x;
    point.y = leftStart.y;

    for (const inset of leftInsets) {
        const insetPoint = new Point(point.x, point.y - inset.height);
        inset.point = insetPoint;
        point.y -= inset.height + INSET_GAP;
        inset.component?.changeDetector.detectChanges();
    }
}

export function stretchBoundsForViewBox() {
    setTimeout(() => {
        // not sure why, but waiting a tick seems necessary
        restoreSelectedBounds();
        boundsAreDirty = true;
        calculateBounds();

        const [priorWidth, priorHeight] = [
            cropArea.bounds.width,
            cropArea.bounds.height
        ];

        expandCropAreaToBounds(minCropBounds);

        if (
            cropArea.bounds.width > priorWidth ||
            cropArea.bounds.height > priorHeight
        ) {
            fixUISize();
            resizeGrayZone();
        }

        repositionInsets();
    });
}

function expandCropAreaToBounds(bounds: paper.Rectangle) {
    if (bounds.right > cropArea.bounds.right) {
        cropArea.bounds.width += bounds.right - cropArea.bounds.right;
    }
    if (bounds.bottom > cropArea.bounds.bottom) {
        cropArea.bounds.height += bounds.bottom - cropArea.bounds.bottom;
    }
    if (bounds.top < cropArea.bounds.top) {
        const diff = cropArea.bounds.top - bounds.top;
        cropArea.bounds.height += diff;
        cropArea.bounds.top -= diff;
    }
    if (bounds.left < cropArea.bounds.left) {
        const diff = cropArea.bounds.left - bounds.left;
        cropArea.bounds.width += diff;
        cropArea.bounds.left -= diff;
    }
}

function getBotCorners() {
    return [
        paper.projects[0].view.projectToView(cropArea.bounds.bottomRight),
        paper.projects[0].view.projectToView(cropArea.bounds.bottomLeft)
    ];
}
