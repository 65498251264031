import {
    Cursor,
    clearCursor,
    setCursor,
    lockCursor,
    unlockCursor
} from "src/app/shared/cursor";
import { DiagramObject } from "../DiagramObject";
import {
    disableMapDragging,
    enableMapDragging
} from "src/app/shared/diagram-map";
import { controlKeyPressed } from "src/app/shared/global-event-states";
import * as paper from "paper";
import { DiagramService } from "src/app/shared/services/diagram.service";
import { AnnotationNode } from "./AnnotationNode";
import {
    DiagramElementAction,
    PropTrackingAction
} from "src/app/shared/Action";
import { globalizeBounds } from "src/app/shared/helpers";

export const ANNOTATION_TOOL_LIST = [
    {
        title: "Textbox",
        icon: "assets/SVG/Annotation Tool Icons/textbox tool.svg",
        help: "Click on the diagram to start drawing. Click again at the desired size."
    },
    {
        title: "Line",
        icon: "assets/SVG/Annotation Tool Icons/line tool.svg",
        help: "Click on the diagram to start drawing. Click again at the desired position."
    },
    {
        title: "Arrow",
        icon: "assets/SVG/Annotation Tool Icons/arrow tool.svg",
        help: "Click on the diagram to start drawing. Click again at the desired position."
    },
    {
        title: "Measure",
        icon: "assets/SVG/Annotation Tool Icons/measure tool.svg",
        help: `Click on the diagram to start drawing. Click again at the desired length. Drag the label to reposition.`
    },
    {
        title: "Curve",
        icon: "assets/SVG/Annotation Tool Icons/curve tool.svg",
        help: "Click on the diagram to start drawing. Click again at the desired length, then once more to set the curvature."
    },
    {
        title: "Polyline",
        icon: "assets/SVG/Annotation Tool Icons/polyline tool.svg",
        help: "Click on the diagram to start drawing. Each subsequent click sets a new point. Double-click to finish."
    },
    {
        title: "Rectangle",
        icon: "assets/SVG/Annotation Tool Icons/rectangle tool.svg",
        help: "Click on the diagram to start drawing. Click again at the desired size."
    },
    {
        title: "Ellipse",
        icon: "assets/SVG/Annotation Tool Icons/ellipse tool.svg",
        help: "Click on the diagram to start drawing. Click again at the desired size."
    },
    {
        title: "Polygon",
        icon: "assets/SVG/Annotation Tool Icons/polygon tool.svg",
        help: "Click on the diagram to start drawing. Each subsequent click sets an edge. Click within the octagon node to finish."
    },
    {
        title: "Freehand",
        icon: "assets/SVG/Annotation Tool Icons/freehand tool.svg",
        help: "Drag on the diagram to draw. Release within the octagon node to create a closed shape, otherwise a line is created."
    },
    {
        title: "Parking-Spaces",
        icon: "assets/SVG/Annotation Tool Icons/parking-spaces tool.svg",
        help: `Click on the diagram to start drawing, again to set the length. Stalls open upward when drawing from left to right.
        Use the ellipsis menu to set stall angle or add curvature nodes.`
    }
    // {
    //     title: "Zig-Zag",
    //     icon: "assets/SVG/Annotation Tool Icons/zig-zag tool.svg",
    //     help: `Click on the diagram to start drawing. Click again at the desired position.`
    // }
];

export interface AnnotationTool {
    changeStrokeColor(color: paper.Color): void;
    changeFillColor?(color: paper.Color): void;
    changeStrokeWidth(width: number): void;
    changeStrokeStyle(style?: string): void;
}

export abstract class Annotation
    extends DiagramObject
    implements AnnotationTool
{
    protected applyClassName() {
        this.className = "Annotation";
    }
    static defaultStrokeColor: paper.Color | null = new paper.Color(0, 0, 0);
    static defaultFillColor: paper.Color | null = null;
    static defaultTextboxFillColor: paper.Color | null = new paper.Color(
        "white"
    );
    static defaultFontColor: paper.Color | null = new paper.Color(0, 0, 0);
    static defaultStrokeWidth = 2;
    static defaultDashArray: [number, number] | [] = [];
    static defaultStrokeStyle = "Solid";
    static defaultArrowVisibility: { Start: boolean; End: boolean } = {
        Start: false,
        End: false
    };
    static defaultFontSize = 18;
    static defaultDecimalPlaces = 0;
    static defaultParkingStallColor: paper.Color | null = new paper.Color(
        "white"
    );
    static defaultStallAngle = 0;
    static readonly nearlyInvisible = new paper.Color(255, 255, 255, 0.001);

    abstract positionEllipsis(): void;

    get shape(): paper.Path {
        return this.entireObject.children["shape"];
    }

    get nodeGroup(): paper.Group {
        return this.entireObject.children["nodes"];
    }

    get controlNodes(): AnnotationNode[] {
        return this.nodeGroup.children.map(
            (n) => DiagramService.getById(n.data.referenceId) as AnnotationNode
        );
    }

    get strokeStyle(): string {
        return this.shape.data.strokeStyle;
    }

    set strokeStyle(style: string) {
        this.shape.data.strokeStyle = style;
    }

    get rotation() {
        return this.entireObject.rotation;
    }

    set rotation(angle: number) {
        this.entireObject.rotation = angle;
        this.ellipsisButton.rotation = -angle;
    }

    /**
     * Change the stroke color of the shape
     * @param color new paper.Color
     */
    abstract changeStrokeColor(color: paper.Color | null): void;

    /**
     * Reposition the nodes when a change happens
     * @param includeEdges
     */
    repositionNodes(includeEdges = false): void {
        if (includeEdges) {
            for (
                let i = 0, j = 0;
                i < this.shape.segments.length;
                ++i, j += 2
            ) {
                this.nodeGroup.children[j].position =
                    this.shape.segments[i].point;
                this.nodeGroup.children[j + 1].position =
                    this.shape.segments[i].curve.getPointAtTime(0.5);
            }
        } else {
            for (let i = 0; i < this.shape.segments.length; ++i) {
                this.nodeGroup.children[i].position =
                    this.shape.segments[i].point;
            }
        }
    }

    onMouseDown(e: paper.MouseEvent) {
        // can only be manipulated if selected first
        if (this.isSelected) {
            /* setting the pivot prior to initializing the action prevents
            propHasChanged being equal to true just because the pivot is different */
            this.shape.parent.pivot = this.shape.parent.globalToLocal(e.point);

            if (!PropTrackingAction.isRecording && !this.movingIsDisabled) {
                new PropTrackingAction<paper.Point>({
                    name: "translate",
                    elementRef: this,
                    propKey: "position",
                    propComparator: (a, b) => a.equals(b)
                }).startRecording();
            }
            this.shape.onMouseDrag = this.dragMove;
            lockCursor();
            disableMapDragging();
        }
    }

    setUpEventHandlers(): void {
        super.setUpEventHandlers();

        this.shape.onMouseEnter = (e: paper.MouseEvent) => {
            if (this.isSelected) {
                setCursor(Cursor.Move);
            } else {
                setCursor(Cursor.Pointer);
            }
        };

        this.shape.onMouseLeave = (e: paper.MouseEvent) => {
            clearCursor();
        };

        this.shape.onClick = (e: paper.MouseEvent) => {
            DiagramObject.select(this);
        };

        this.shape.onMouseDown = this.onMouseDown.bind(this);

        this.shape.onMouseUp = (e: paper.MouseEvent) => {
            unlockCursor();
            this.shape.off("mousedrag", this.dragMove);
            if (!controlKeyPressed) enableMapDragging();

            const mutation = PropTrackingAction.currentAction;

            if (mutation && mutation instanceof PropTrackingAction) {
                if (mutation.propHasChanged) {
                    mutation.stopRecording();
                } else {
                    mutation.cancel();
                }
            }
        };
    }

    /**
     * Change the stroke width of the line or border of the tool
     * @param width
     */
    changeStrokeWidth(width: number) {
        if (this.shape.strokeWidth === width) return;

        const strokeWidthChangeAction = new DiagramElementAction({
            name: "change stroke width",
            elementRef: this
        });

        strokeWidthChangeAction.startRecording();

        this.shape.strokeWidth = Annotation.defaultStrokeWidth = width;

        if (this.shape.dashArray.length) {
            this.changeStrokeStyle();
        }

        strokeWidthChangeAction.stopRecording();
    }

    /**
     * Change the stroke style of the line or border of the tool
     * @param style
     */
    changeStrokeStyle(style?: string) {
        let dashArray: [number, number] | [] = [];
        const sw = this.shape.strokeWidth;

        if (!style) style = this.strokeStyle;

        let strokeStyleChangeAction: DiagramElementAction | undefined =
            undefined;

        if (style != this.strokeStyle && !DiagramElementAction.isRecording) {
            strokeStyleChangeAction = new DiagramElementAction({
                name: "change stroke style",
                elementRef: this
            });

            strokeStyleChangeAction.startRecording();
        }

        switch (style) {
            case "Dashed":
                dashArray = [3 * sw, 3 * sw];
                break;
            case "Dotted":
                dashArray = [1, 2 * sw];
        }

        this.shape.data.strokeStyle = Annotation.defaultStrokeStyle = style;
        this.shape.dashArray = Annotation.defaultDashArray = dashArray;

        if (strokeStyleChangeAction) {
            strokeStyleChangeAction.stopRecording();
        }
    }

    /**
     * Select the tool to be in used
     */
    select() {
        super.select();
        this.nodeGroup.visible = true;
        this.ellipsisButton.visible = true;
        this.ellipsisButton.rotation = -this.entireObject.rotation;
    }

    /**
     * Deselect the tool that was in used
     */
    deselect() {
        super.deselect();
        this.nodeGroup.visible = false;
        this.ellipsisButton.visible = false;
    }

    protected passEvent = (e: paper.MouseEvent) => {
        e.currentTarget = this.shape;
        e.target = this.shape;
        this.shape.emit(e.type, e);
    };

    getGlobalBounds(): paper.Rectangle {
        return globalizeBounds(this.shape.bounds, this.shape);
    }
}
