import { GroupSelection } from "./../../classes/GroupSelection";
import { Injectable } from "@angular/core";
import Watchable from "@blakecanter/watchablejs";
import * as paper from "paper";
import {
    disablePage,
    enablePage,
    promptBox,
    startMajorDraw,
    finishMajorDraw
} from "src/app/page-disabler/disable-enable-page";
import { GeolocationService } from "./geolocation.service";
import {
    crashLatLng,
    disableMapDragging,
    enableMapDragging,
    getCrashLatLngAsPaperPoint,
    goHome,
    leafletMap,
    setLayer,
    setStyle,
    setUpMap
} from "../diagram-map";
import {
    DiagramLayer,
    getLayer,
    createCrashIconAndLayers,
    unlockLayers
} from "../layer";
import { getDiagramZoomScale, normalizeInsetScale, pause, repositionDiagram } from "../helpers";
import { CRUDService } from "./crud.service";
import { CrashReportService } from "./crash-report.service";
import { DiagramAutomationService } from "./diagram-automation.service";
import { DiagramElement } from "src/app/classes/DiagramElement";
import { controlKeyPressed, setUpKeyHandlers } from "../global-event-states";
import { DiagramObject } from "src/app/classes/DiagramObject";
import { CircleSelectedObject } from "src/app/classes/CircleSelectedObject";
import { EllipseNode } from "src/app/classes/AnnotationTools/EllipseTool";
import { LineNode, LineTool } from "src/app/classes/AnnotationTools/LineTool";
import {
    ParkingStallNode,
    ParkingStallTool
} from "src/app/classes/AnnotationTools/ParkingStallTool";
import { PolygonNode } from "src/app/classes/AnnotationTools/PolygonTool";
import { RectangleNode } from "src/app/classes/AnnotationTools/RectangleTool";
import {
    TextboxNode,
    TextboxTool
} from "src/app/classes/AnnotationTools/TextboxTool";
import { NonMotorist } from "src/app/classes/NonMotorist";
import { Trailer } from "src/app/classes/Trailer";
import { Vehicle } from "src/app/classes/Vehicle";
import { Arrow, ArrowNode, PhantomNode } from "src/app/classes/Arrow";
import { scaleEllipsis, setUpEllipsisButton } from "../ellipsis-button";
import { ContextMenuComponent } from "@progress/kendo-angular-menu";
import { DiagramAction, setUpActionManager } from "../Action";
import {
    GeometricShape,
    RotationNode
} from "src/app/classes/AnnotationTools/GeometricShape";
import { CrashPointObject } from "src/app/classes/CrashPointObject";
import { UIService } from ".";
import Point from "../S4Point";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root"
})
export class DiagramService {
    private textboxDiv: HTMLDivElement;
    private canvas: HTMLCanvasElement;
    private mapDiv: HTMLElement;
    private ellipsisMenu: ContextMenuComponent;
    isReady = new Watchable(false);

    static globalInstance: DiagramService;
    static set crashPointObject(obj: CrashPointObject) {
        if (getLayer(DiagramLayer.crashPoint))
            getLayer(DiagramLayer.crashPoint).data.crashPointId = obj.id;
    }
    static get crashPointObject() {
        return this.getById(
            getLayer(DiagramLayer.crashPoint).data.crashPointId
        ) as CrashPointObject;
    }
    static ellipsisMenu: ContextMenuComponent;
    private static objectMap = new Map<string, DiagramElement>();
    private static readonly subclasses: Record<
        string,
        new (entireObject: paper.Item) => DiagramElement
    > = {
        Arrow: Arrow,
        ArrowNode: ArrowNode,
        PhantomNode: PhantomNode,
        CircleSelectedObject: CircleSelectedObject,
        NonMotorist: NonMotorist,
        Trailer: Trailer,
        Vehicle: Vehicle,
        EllipseNode: EllipseNode,
        LineTool: LineTool,
        LineNode: LineNode,
        ParkingStallTool: ParkingStallTool,
        ParkingStallNode: ParkingStallNode,
        PolygonNode: PolygonNode,
        RectangleNode: RectangleNode,
        TextboxTool: TextboxTool,
        TextboxNode: TextboxNode,
        GroupSelection: GroupSelection,
        RotationNode: RotationNode,
        CrashPointObject: CrashPointObject,
        GeometricShape: GeometricShape
    };

    static *diagramObjectIterator() {
        for (const object of this.objectMap.values()) {
            if (object instanceof DiagramObject) {
                yield object;
            }
        }
    }

    static *diagramElementIterator() {
        yield* this.objectMap.values();
    }

    static addElement(element: DiagramElement) {
        this.objectMap.set(element.id, element);
    }

    static removeElement(element: DiagramElement) {
        this.objectMap.delete(element.id);
    }

    static getById(id: string) {
        let object = this.objectMap.get(id);

        if (!object || !object.entireObject) {
            let item = paper.projects[0].getItem({ data: { referenceId: id } });
            if (!item) throw new Error("Invalid object ID");
            object = this.restore(item as paper.Group, id);
        }

        return object;
    }

    static elementExists(id: string) {
        return this.objectMap.has(id);
    }

    static getByItem(item: paper.Item) {
        while (item && item.data && !item.data.referenceId) {
            item = item.parent;
        }

        if (!item || !item.data) return undefined;

        return this.getById(item.data.referenceId);
    }

    static restore(entireObject: paper.Item, id?: string): DiagramElement {
        if (!id) id = entireObject.data.referenceId as string;
        if (this.objectMap.has(id)) return this.objectMap.get(id)!;
        const Subclass = this.subclasses[entireObject.data.className];
        let instance: any;
        try {
            instance = new Subclass(entireObject);
        } catch (error: any) {
            console.warn(
                "restore threw an error trying to restore a " +
                    entireObject.className +
                    " the entireObject of class " +
                    entireObject.data.className
            );
        }
        this.objectMap.set(id, instance);
        return instance;
    }

    static restoreSome(entireObjects: paper.Group[]) {
        const restored = new Array<DiagramElement>();
        for (const entireObject of entireObjects)
            restored.push(this.restore(entireObject));
        return restored;
    }

    /** Loops through all interactive diagram elements found in paper.projects[0] and instantiates their class wrappers. */
    static restoreAll(): void {
        DiagramObject.selectedObject = undefined;
        const interactiveObjects = paper.projects[0].getItems({
            data: { isInteractive: true }
        });
        const removedElements = new Set<DiagramElement>(
            this.objectMap.values()
        );
        this.objectMap.clear();
        for (const interactiveObject of interactiveObjects as paper.Group[]) {
            removedElements.delete(this.restore(interactiveObject));
        }
        for (const element of removedElements) {
            if (element instanceof TextboxTool) {
                element.removeTextArea();
            }
        }
    }

    /**
     * @param route The route object of the browser navigation.
     */
    constructor(
        private geoService: GeolocationService,
        private crud: CRUDService,
        private crashReport: CrashReportService,
        private automate: DiagramAutomationService,
        private ui: UIService
    ) {
        DiagramService.globalInstance = this;
    }

    /**
     *
     * @param canvas
     * @param mapDiv
     */
    async init(
        canvas: HTMLCanvasElement,
        mapDiv: HTMLDivElement,
        textboxDiv: HTMLDivElement,
        ellipsisMenu: ContextMenuComponent
    ) {
        this.mapDiv = mapDiv;
        this.canvas = canvas;
        this.textboxDiv = textboxDiv;
        this.ellipsisMenu = DiagramService.ellipsisMenu = ellipsisMenu;
        paper.setup(this.canvas);
        return this.setUpMapAndDiagram();
    }

    /** Set up the Diagram  */
    async setUpMapAndDiagram() {
        disablePage();

        this.geoService.geoDataIsLoaded.when(true, () => {
            // Initialize the map
            setUpMap(
                this.mapDiv,
                this.geoService.latitude,
                this.geoService.longitude
            );

            // Load the diagram objects when the map is set up
            leafletMap.whenReady(async () => {
                await this.setUpDiagram();

                // this.ui.insets.push({
                //     latLng: crashLatLng,
                //     point: new Point(10, 75),
                //     width: 200,
                //     height: 200
                // });
            });
        });
    }

    private async setUpDiagram(skipLoadingSavedDiagram = false) {
        try {
            const dataLoadPromises: Promise<any>[] = [
                this.crashReport.participantsAreLoaded.promiseWhen(true)
            ];

            if (!skipLoadingSavedDiagram) {
                dataLoadPromises.push(this.loadDiagram());
            }

            await Promise.all(dataLoadPromises); // allows parallel processing (maybe)
            await setUpEllipsisButton(this.ellipsisMenu);
            this.setUpGlobalHandlers();

            if (this.crud.savedDiagramWasLoaded && !skipLoadingSavedDiagram) {
                this.crashReport.mergeCrashReportUpdatesWithDiagram();
            } else {
                DiagramService.crashPointObject =
                    await createCrashIconAndLayers();
                await this.automate.placeParticipants();
                await this.crud.saveDiagram({
                    diagram: true,
                    mapBounds: true,
                    opacity: true,
                    tiles: true
                });
            }

            this.geoService.correctLocationDirty();
            await setUpActionManager();
            this.isReady.value = true;
            enablePage();
        } catch (error) {
            console.error(error);
            const choice = await promptBox(
                "Resource Load Error",
                "Something went wrong when loading the diagram.",
                ["Try again", "Reset Diagram", "Exit app"]
            );
            if (choice === "Reset Diagram") {
                const followUp = await promptBox(
                    "Reset Warning",
                    "This will permanently erase any saved diagram data and reset the diagram",
                    ["Proceed", "Cancel"]
                );
                leafletMap.scrollWheelZoom.enable();
                enableMapDragging();
                paper.projects[0].clear();
                await this.setUpDiagram(followUp === "Proceed");
            } else if (choice === "Exit app") {
                window.close();
                disablePage();
            } else {
                await this.setUpDiagram();
            }
        }
    }

    private setUpGlobalHandlers() {
        setUpKeyHandlers();
        this.setUpMapHandlers();

        paper.projects[0].view.onMouseDown = (e: paper.MouseEvent) => {
            if (!controlKeyPressed && DiagramObject.selectedObject) {
                const clickedOn = DiagramService.getByItem(e.target);

                if (
                    !clickedOn ||
                    (DiagramObject.selectedObject instanceof GroupSelection &&
                        clickedOn !== DiagramObject.selectedObject)
                ) {
                    DiagramObject.deselect();
                }
            }
        };

        document.body.addEventListener("contextmenu", (e) => {
            e.preventDefault();
        });

        if (window.sessionStorage.getItem("userIsTesting") == "true") {
            window.addEventListener("error", async (e) => {
                await promptBox(
                    "Application Error",
                    `Send the following to Blake for debugging:<br><br>${e.message}`
                );
            });
        }

        window.addEventListener("unload", () => {
            this.crud.saveDiagram(
                {
                    mapBounds: true,
                    tiles: true,
                    opacity: true,
                    diagram: true,
                    styleCD: true
                },
                true
            );
        });
    }

    private setUpMapHandlers() {
        leafletMap.on("move", () => {
            repositionDiagram();
        });

        leafletMap.on("moveend", () => {
            this.crud.saveDiagram({ mapBounds: true, diagram: true });
        });

        leafletMap.on("zoomend", () => {
            this.scaleStuff();
        });

        leafletMap.on("zoom", () => {
            paper.projects[0].view.zoom = getDiagramZoomScale();
        });
    }

    scaleStuff() {
        const zoomScale = getDiagramZoomScale();
        this.textboxDiv.style.transform = `scale(${zoomScale})`;
        scaleEllipsis();

        for (const obj of DiagramService.diagramObjectIterator()) {
            if (obj instanceof CircleSelectedObject) {
                obj.normalizeSelectionUISize();
            }
        }

        const insets = paper.projects[0].getItems({
            data: { isViewBox: true }
        });

        for (const inset of insets) {
            normalizeInsetScale(inset);
        }
    }

    setDiagramLatLng() {
        const latLngPoint = getCrashLatLngAsPaperPoint();
        DiagramService.crashPointObject.position = latLngPoint;

        for (const layer of paper.projects[0].layers) {
            layer.pivot = latLngPoint;
        }
    }

    async loadDiagram(): Promise<boolean> {
        await this.crashReport.loadParticipants();
        const fetchResult = await this.crud.fetchDiagram();

        if (!fetchResult.ok) {
            return false;
        } else if (fetchResult.data) {
            disableMapDragging();
            leafletMap.scrollWheelZoom.disable();

            leafletMap.setZoom(fetchResult.data.zoom, { animate: false }).panTo(
                {
                    lat: fetchResult.data.lat,
                    lng: fetchResult.data.lng
                },
                { animate: false }
            );

            setLayer(fetchResult.data.tiles, fetchResult.data.opacity);
            if (fetchResult.data.styleCD) {
                setStyle(fetchResult.data.styleCD);
            }

            paper.projects[0].clear();
            paper.projects[0].importJSON(fetchResult.data.json);

            const crashIcon = paper.projects[0].getItem({
                data: { isCrashIcon: true }
            });

            if (!crashIcon && environment.production) {
                window.location.replace(
                    "https://diagram.signal4analytics.com/v1x/diagram" +
                        location.search
                );
                return false;
            }

            DiagramService.crashPointObject = DiagramService.restore(
                crashIcon
            ) as CrashPointObject;

            getLayer(DiagramLayer.main).activate();

            const zoomScale = getDiagramZoomScale();
            this.textboxDiv.style.transform = `scale(${zoomScale})`;
            paper.projects[0].view.zoom = zoomScale;

            await DiagramService.reestablish();
            this.setDiagramLatLng();

            enableMapDragging();
            leafletMap.scrollWheelZoom.enable();
            unlockLayers();

            return true;
        }

        return false;
    }

    static async reestablish() {
        repositionDiagram();
        await setUpEllipsisButton(this.ellipsisMenu);

        this.restoreAll();
        this.globalInstance.scaleStuff();

        let wasSelected: paper.Item | DiagramObject = paper.projects[0].getItem(
            {
                data: { wasSelected: true }
            }
        );

        if (wasSelected) {
            wasSelected = this.getByItem(
                wasSelected as paper.Item
            ) as DiagramObject;
        }

        const stillHaveSelectionUIBugs = paper.projects[0].getItems({
            name: "selection controls",
            visible: true
        });

        for (const controls of stillHaveSelectionUIBugs) {
            const controlsParent = this.getByItem(
                controls
            ) as CircleSelectedObject;
            if (!(controlsParent instanceof GroupSelection))
                controlsParent?.deselect();
        }

        if (wasSelected) DiagramObject.select(wasSelected);

        this.globalInstance.crashReport.recheckDiagrammedParticipants();
        // CRUDService.requestSave();
    }

    async resetDiagram() {
        const resetAction = new DiagramAction({ name: "reset" });
        resetAction.startRecording();
        disablePage();

        DiagramObject.deselect();

        while (this.textboxDiv.firstChild) {
            this.textboxDiv.removeChild(this.textboxDiv.lastChild as ChildNode);
        }

        paper.projects[0].clear();
        DiagramService.objectMap.clear();
        this.ui.insets.length = 0;

        await setUpEllipsisButton(this.ellipsisMenu);
        DiagramService.crashPointObject = await createCrashIconAndLayers();

        for (const p of this.crashReport.participants) {
            p.isDiagrammed = false;
        }

        goHome();
        await this.automate.placeParticipants();

        await pause(250);

        resetAction.stopRecording();
        enablePage();
    }
}
