import {
    addGhost,
    addPostPositionOption,
    addPriorPositionOption,
    arrowColorMenu,
    opacityOptions,
    removeOption,
    resetArrowOptions,
    reverseDirectionOptions,
    selectAllPositionsOption
} from "./ellipsis-menu-options";
import { DiagramAction } from "./../shared/Action";
import { Labeled } from "../models/implementables";
import * as paper from "paper";
import { Trailer } from "./Trailer";
import { CircleSelectedObject } from "./CircleSelectedObject";
import { DiagramObject } from "./DiagramObject";
import {
    getDiagramZoomScale,
    leafletMap,
    projectPointToLatLng
} from "../shared/diagram-map";
import { DiagramLayer, getLayer } from "../shared/layer";
import { ParticipantData } from "../cdt-diagram/cdt-diagram-models";
import { loadSVGFromFile, loadSVGFromPage, wrapIndex } from "../shared/helpers";
import { getIconScale } from "../shared/icon";
import { LatLng } from "leaflet";
import { Arrow } from "./Arrow";
import { DiagramService } from "../shared/services/diagram.service";
import { ParticipantBuilder } from "./ObjectBuilders";
import { DiagramElementAction } from "../shared/Action";
import { CrashReportService } from "../shared/services/crash-report.service";
import {
    finishMajorDraw,
    startMajorDraw
} from "../page-disabler/disable-enable-page";
import { MenuItem } from "@progress/kendo-angular-menu";
import { GroupSelectionBuilder } from "./GroupSelection";
import { clearCursor, Cursor, setCursor } from "../shared/cursor";

export const NUM_OF_ANCHORS = 32;

export abstract class Participant
    extends CircleSelectedObject
    implements Labeled
{
    protected getCommonEllipsisOptions(): [MenuItem[], MenuItem[]] {
        const commonFirst = this.isGhost
            ? [addGhost]
            : [addPriorPositionOption, addPostPositionOption];

        commonFirst.push(selectAllPositionsOption);

        const commonLast = [reverseDirectionOptions, arrowColorMenu];

        if (this.arrowsAreMutated) commonLast.push(resetArrowOptions);

        commonLast.push(removeOption);

        if (this.isGhost) commonLast.unshift(opacityOptions);

        return [commonFirst, commonLast];
    }

    protected static readonly COPIES_OVER = [
        "inAnchor",
        "outAnchor",
        "color",
        "arrowColor",
        "participantData",
        "orientation",
        "baseScaling",
        "icon",
        "ownerId",
        "subtype",
        "_positionsCache"
    ];

    private lastClick = 0;

    get numPositions(): number {
        return this.allPositions.length;
    }

    protected _positionsCache?: Array<Participant>;

    get allPositions(): Array<Participant> {
        if (!this._positionsCache || this._positionsCache.length === 0) {
            // restore shared reference
            let position: Participant | undefined = this;
            this._positionsCache = [this];
            while ((position = position.prevPositionViaArrow)) {
                this._positionsCache.unshift(position);
                position._positionsCache = this._positionsCache;
            }
            position = this;
            while ((position = position.nextPositionViaArrow)) {
                this._positionsCache.push(position);
                position._positionsCache = this._positionsCache;
            }
        }

        return this._positionsCache;
    }

    set position(p: paper.Point) {
        super.position = p;
        this.updateArrows();

        if (this.hasTrailer) {
            this.trailer!.reposition();
        }
    }

    get position() {
        return super.position;
    }

    get rotation() {
        return super.rotation;
    }

    get quickAddFront(): paper.Path {
        return this.selectionControls.children["quick add front"];
    }

    get quickAddNext(): paper.Path {
        if (this.isReversing) {
            return this.quickAddBack;
        }

        return this.quickAddFront;
    }

    get quickAddBack(): paper.Path {
        return this.selectionControls.children["quick add back"];
    }

    get quickAddPrev(): paper.Path {
        if (this.isReversing) {
            return this.quickAddFront;
        }

        return this.quickAddBack;
    }

    set rotation(degrees: number) {
        this.innerGroup.rotation = degrees;

        for (const quickAddPos of [this.quickAddNext, this.quickAddPrev]) {
            const plusSign = quickAddPos.children["plus sign"];
            plusSign.rotation = -degrees;
        }

        if (this.hasTrailer) {
            this.trailer!.reposition();
        }

        this.updateArrows();
    }

    get core(): paper.SymbolItem {
        return super.core as paper.SymbolItem;
    }

    get symbolDefinition(): paper.SymbolDefinition {
        return this.core.definition;
    }

    get symbolDefinitionItem(): paper.Item {
        return this.symbolDefinition.item;
    }

    set symbolDefinitionItem(newItem: paper.Item) {
        this.symbolDefinition.item = newItem;
    }

    set ownerId(id: string | undefined) {
        this.core.data.ownerId = id;
    }

    get ownerId(): string | undefined {
        return this.core.data.ownerId;
    }

    get opacity() {
        return this.core.opacity;
    }

    set opacity(opacity: number) {
        this.core.opacity = opacity;
    }

    get isGhost() {
        return this.core.data.ownerId != undefined;
    }

    get primaryPosition(): Participant {
        return this.ownerId
            ? (DiagramService.getById(this.ownerId) as Participant)
            : this;
    }

    get isPrimary() {
        return !this.isGhost;
    }

    get moment() {
        return this.core.data.moment;
    }

    set moment(moment: "Post" | "Prior" | "Present") {
        this.core.data.moment = moment;
    }

    get isHitAndRun(): boolean {
        return this.core.data.isHitAndRun;
    }

    set isHitAndRun(value: boolean) {
        this.core.data.isHitAndRun = value;
    }

    get isFromCrashReport() {
        return this.primaryPosition.core.data.participantIndex != undefined;
    }

    get participantData(): ParticipantData | undefined {
        return this.core.data.participantData as ParticipantData | undefined;
    }

    set participantData(data: ParticipantData | undefined) {
        this.core.data.participantData = data;
    }

    get participantIndex(): number | undefined {
        return this.core.data.participantIndex;
    }

    set participantIndex(index: number | undefined) {
        this.core.data.participantIndex = index;
    }

    get border() {
        return this.innerGroup.children["border"] as paper.Path;
    }

    get label(): paper.PointText {
        return this.entireObject.children["label"];
    }

    get labelText(): string {
        return this.label.content;
    }

    set labelText(text: string) {
        this.label.content = text;
        this.repositionLabel();
    }

    get hasTrailer() {
        return this.core.data.trailerId != undefined;
    }

    get orientation() {
        return this.core.data.orientation;
    }

    set orientation(orientation: string) {
        this.core.data.orientation = orientation;
    }

    get trailer() {
        if (this.hasTrailer)
            return DiagramService.getById(this.core.data.trailerId) as Trailer;
        return undefined;
    }

    set trailer(t: Trailer | undefined) {
        this.core.data.trailerId = t?.id;
    }

    get arrows() {
        const arrows = new Array<Arrow>();
        const outArrow = this.outArrow;
        const inArrow = this.inArrow;
        if (inArrow) arrows.push(inArrow);
        if (outArrow) arrows.push(outArrow);
        return arrows;
    }

    get outArrow() {
        if (this.core.data.outArrowId)
            return DiagramService.getById(this.core.data.outArrowId) as Arrow;
        return undefined;
    }

    set outArrow(a: Arrow | undefined) {
        this.core.data.outArrowId = a?.id;
    }

    get inArrow() {
        if (this.core.data.inArrowId)
            return DiagramService.getById(this.core.data.inArrowId) as Arrow;
        return undefined;
    }

    set inArrow(a: Arrow | undefined) {
        this.core.data.inArrowId = a?.id;
    }

    get arrowColor(): string | null {
        return this.core.data.arrowColor ?? this.core.data.color;
    }

    set arrowColor(colorCode: string | null) {
        if (this.arrowColor === colorCode) return;

        const arrows = new Set<Arrow>();

        for (const pos of this.allPositions) {
            pos.core.data.arrowColor = colorCode;
            const posArrows = pos.arrows;
            for (const arrow of posArrows) {
                arrows.add(arrow);
            }
        }

        for (const arrow of arrows) {
            arrow.color = colorCode;
        }
    }

    get color() {
        return this.core.data.color;
    }

    set color(colorCode: string) {
        this.core.data.color = colorCode;
    }

    get connectedParticipant(): Participant | undefined {
        return this.trailer;
    }

    get isPriorPosition() {
        return this.index < this.primaryPosition.index;
    }

    get isPostPosition() {
        return this.index > this.primaryPosition.index;
    }

    get outAnchor(): number {
        return this.core.data.outAnchor;
    }

    set outAnchor(pos: number) {
        this.core.data.outAnchor = pos;
        if (this.hasTrailer) {
            this.trailer!.outAnchor != pos && (this.trailer!.outAnchor = pos);
        }
        if (!this.prevPosition) {
            this.core.data.inAnchor = this.getOppositeAnchorIndex(pos);
        }
    }

    get inAnchor(): number {
        return this.core.data.inAnchor;
    }

    set inAnchor(pos: number) {
        this.core.data.inAnchor = pos;
        if (this.hasTrailer) {
            this.trailer!.inAnchor != pos && (this.trailer!.inAnchor = pos);
        }
        if (!this.nextPosition) {
            this.core.data.outAnchor = this.getOppositeAnchorIndex(pos);
        }
    }

    get isReversing(): boolean {
        return this.anchorIsOnBackHalf(this.outAnchor);
    }

    anchorIsOnFrontHalf(anchor: number) {
        return !this.anchorIsOnBackHalf(anchor);
    }

    anchorIsOnBackHalf(anchor: number) {
        const quarterAnchors = NUM_OF_ANCHORS / 4;
        return (
            anchor < NUM_OF_ANCHORS / 2 + quarterAnchors &&
            anchor > NUM_OF_ANCHORS / 2 - quarterAnchors
        );
    }

    arrowBetween(otherPos: Participant) {
        for (const arrow of this.arrows) {
            if (arrow.isConnectedTo(otherPos)) {
                return arrow;
            }
        }

        return undefined;
    }

    reverse() {
        let reverseDirAction: DiagramElementAction | undefined;
        if (!DiagramElementAction.isRecording) {
            reverseDirAction = new DiagramElementAction({
                name: "reverse direction",
                elementRef: this
            });
            reverseDirAction.startRecording();
        }

        this.reverseAnchor("in");
        if (this.prevPosition || this.nextPosition) this.reverseAnchor("out");
        const outArrow = this.outArrow || this.trailer?.outArrow;
        outArrow?.target.reverseAnchor("in");
        const inArrow = this.inArrow || this.trailer?.inArrow;
        inArrow?.origin.reverseAnchor("out");

        if (this.prevPosition && this.nextPosition) {
            for (const pos of [this.prevPosition, this.nextPosition]) {
                const vector = pos
                    .globalPointFor("position")
                    .subtract(this.globalPointFor("position"));
                pos.position = this.globalPointFor("position").subtract(vector);
                pos.resetArrows();
            }
        } else {
            const reversingWith = this.prevPosition || this.nextPosition;
            if (reversingWith) {
                const vector = this.globalPointFor("position").subtract(
                    reversingWith.globalPointFor("position")
                );
                this.position = reversingWith
                    .globalPointFor("position")
                    .subtract(vector);
                this.resetArrows();
            }
        }

        this.updateArrows();
        this.updateQuickAdds();

        if (reverseDirAction) {
            reverseDirAction?.stopRecording();
        }
    }

    protected reverseAnchor(dir: "in" | "out") {
        const anchor = `${dir}Anchor`;
        this[anchor] = this.getOppositeAnchorIndex(this[anchor]);

        if (this.hasTrailer) {
            const arrowProp = `${dir}Arrow`;
            const changeProp = dir === "in" ? "changeTarget" : "changeOrigin";

            const arrow = this[arrowProp] || this.trailer![arrowProp];

            if (arrow) {
                if (this.anchorIsOnBackHalf(this[anchor])) {
                    arrow[changeProp](this.trailer!);
                } else if (this.anchorIsOnFrontHalf(this[anchor])) {
                    arrow[changeProp](this);
                }
            }
        }
    }

    get arrowsAreMutated() {
        return (
            this.outArrow?.nodeGroup.children.length ||
            this.inArrow?.nodeGroup.children.length
        );
    }

    resetArrows() {
        if (!this.arrowsAreMutated) return;

        let resetArrowsAction: DiagramElementAction | undefined = undefined;

        if (!DiagramElementAction.isRecording) {
            resetArrowsAction = new DiagramElementAction({
                name: "reset arrows",
                elementRef: this
            });
            resetArrowsAction.startRecording();
        }

        for (const arrow of this.arrows) {
            arrow.reset();
        }

        this.requestMenuUpdate();

        if (resetArrowsAction) {
            resetArrowsAction.stopRecording();
        }
    }

    resetScale(): void {
        let resetScaleAction: DiagramElementAction | undefined = undefined;

        if (!DiagramAction.isRecording) {
            resetScaleAction = new DiagramElementAction({
                name: "reset scale",
                elementRef: this
            });

            resetScaleAction.startRecording();
        }

        startMajorDraw();

        Arrow.trackUpdates();
        for (const pos of this.allPositions) {
            pos.innerGroup.scaling = pos.baseScaling;
            pos.normalizeSelectionUISize();
            pos.trailer?.reposition;
        }
        for (const pos of this.allPositions) {
            pos.updateArrows();
        }
        Arrow.untrackUpdates();

        finishMajorDraw();
        this.requestMenuUpdate();

        if (resetScaleAction) {
            resetScaleAction.stopRecording();
        }
    }

    private unlockLabel() {
        this.label.locked = false;
    }

    setUpEventHandlers(): void {
        this.unlockLabel = this.unlockLabel.bind(this);
        super.setUpEventHandlers();
        this.setUpEventPassing(this.label);
        this.label.onMouseMove = (e: paper.MouseEvent) => {
            let hiddenTarget = this.rotationHandle.contains(
                this.rotationHandle.globalToLocal(e.point)
            )
                ? this.rotationHandle
                : this.scaleHandle?.contains(
                      this.innerGroup.globalToLocal(e.point)
                  )
                ? this.scaleHandle
                : undefined;

            if (hiddenTarget) {
                this.label.locked = true;
                hiddenTarget.onMouseEnter!();
            }
        };

        this.rotationHandle.on({
            mouseleave: this.unlockLabel,
            mouseup: this.unlockLabel
        });

        this.quickAddFront.on({
            mouseenter: () => {
                setCursor(Cursor.Copy);
            },
            mouseleave: () => {
                clearCursor();
            },
            click: () => {
                const dir = this.isReversing ? "Prior" : "Post";
                const pos = this.isReversing ? "prevPosition" : "nextPosition";
                this.addPosition(dir);
                clearCursor();
                DiagramObject.select(this[pos]!);
            }
        });

        this.quickAddBack.on({
            mouseenter: () => {
                setCursor(Cursor.Copy);
            },
            mouseleave: () => {
                clearCursor();
            },
            click: () => {
                const dir = this.isReversing ? "Post" : "Prior";
                const pos = this.isReversing ? "nextPosition" : "prevPosition";
                this.addPosition(dir);
                clearCursor();
                DiagramObject.select(this[pos]!);
            }
        });
    }

    onMouseUp(e: paper.MouseEvent): void {
        const now = performance.now();

        if (this.allPositions.length > 1) {
            if (now - this.lastClick <= 300) {
                const gsBuilder = new GroupSelectionBuilder();
                gsBuilder.build(this.allPositions);
            }
        }

        this.lastClick = now;
        super.onMouseUp(e);
    }

    setUpScaleHandleEvents(): void {
        super.setUpScaleHandleEvents();
        this.scaleHandle.on({
            mouseleave: this.unlockLabel,
            mouseup: this.unlockLabel
        });
    }

    updateArrows() {
        const arrows = this.arrows;

        if (this.hasTrailer) {
            arrows.push(...this.trailer!.arrows);
        }

        for (const arrow of arrows) {
            if (arrow) {
                arrow.update();
            }
        }
    }

    abstract repositionLabel(): void;

    getAnchorPoint(offset: number) {
        return this.border.parent.localToGlobal(
            this.border.getPointAt(
                (this.border.length / NUM_OF_ANCHORS) * offset
            )
        );
    }

    changeLabel(text: string) {
        for (const pos of this.allPositions) {
            pos.labelText = text;
        }
    }

    /** Apply **fn** to every ghost (prior and post positions) */
    forAllGhosts(fn: (ghost: Participant) => void) {
        const allPos = [...this.allPositions];

        for (const pos of allPos) {
            if (pos.isPrimary) continue;
            fn(pos);
        }
    }

    get index() {
        return this.allPositions.indexOf(this);
    }

    private get nextPositionViaArrow(): Participant | undefined {
        let next = this.outArrow?.target || this.trailer?.outArrow?.target;

        if (next && next.className != this.className) {
            next = (next as Trailer).towingParticipant;
        }

        return next;
    }

    private get prevPositionViaArrow(): Participant | undefined {
        let prev = this.inArrow?.origin || this.trailer?.inArrow?.origin;

        if (prev && prev.className != this.className) {
            prev = (prev as Trailer).towingParticipant;
        }

        return prev;
    }

    get nextPosition(): Participant | undefined {
        return this.allPositions[this.index + 1];
    }

    get prevPosition(): Participant | undefined {
        return this.allPositions[this.index - 1];
    }

    protected scaleBy(amount: number) {
        if (!super.scaleBy(amount)) return false;
        this.repositionLabel();
        return true;
    }

    protected setScale(p: paper.Point) {
        this.innerGroup.scaling = p;
        this.normalizeSelectionUISize();
        this.positionEllipsis();
        this.repositionLabel();
    }

    dragScale = (e: paper.MouseEvent) => {
        const scaleAmount = this.getScaleToPoint(e.point);

        if (this.scaleBy(scaleAmount)) {
            if (this.hasTrailer) {
                this.trailer!.reposition();
            }

            this.updateArrows();

            if (!this.isGhost) {
                Arrow.trackUpdates();

                this.forAllGhosts((ghost: Participant) => {
                    ghost.setScale(this.innerGroup.scaling);

                    if (ghost.hasTrailer) {
                        ghost.trailer!.reposition();
                    }

                    ghost.updateArrows();
                });

                Arrow.untrackUpdates();
            }
        }
    };

    private *uniqueSymbolDefs(): Generator<
        [paper.SymbolDefinition, Participant]
    > {
        let def: paper.SymbolDefinition | undefined = undefined;

        for (const pos of this.allPositions) {
            if (pos.symbolDefinition !== def) {
                def = pos.symbolDefinition;
                yield [def, pos];
            }
        }
    }

    private getFurthestPosition(moment: "Prior" | "Post"): Participant {
        if (moment === "Prior") {
            return this.allPositions[0];
        } else {
            return this.allPositions.at(-1)!;
        }
    }

    /** Copies this, providing the copy with a new id clearing all but relevant data attributes */
    protected clone() {
        const builder = new ParticipantBuilder<this>();
        const clone = builder.clone(this);

        for (const attribute of Participant.COPIES_OVER) {
            clone[attribute] = this[attribute];
        }

        return clone;
    }

    protected getOppositeAnchorIndex(anchorIndex: number) {
        return (anchorIndex + NUM_OF_ANCHORS / 2) % NUM_OF_ANCHORS;
    }

    addPosition(moment: "Prior" | "Post"): Participant {
        let addingPosition: DiagramElementAction | undefined;

        if (!DiagramAction.isRecording && DiagramAction.isReady) {
            addingPosition = new DiagramElementAction({
                name: `add ${moment.toLowerCase()} position`
            });
        }

        startMajorDraw();

        const mainPos = (
            this.isGhost ? DiagramService.getById(this.ownerId!) : this
        ) as Participant;
        const priorPos = this.getFurthestPosition(moment);
        const ghost = priorPos.clone();
        ghost.ownerId = mainPos.id;
        ghost.opacity = 0.5;
        ghost.moment = moment;

        // set the ghost's out anchor to the opposite of the in anchor.
        if (moment == "Post") {
            this.allPositions.push(ghost);
            priorPos.outAnchor = ghost.outAnchor = this.getOppositeAnchorIndex(
                ghost.inAnchor
            );
            ghost.prevPosition!.quickAddNext.visible = false;
            ghost.quickAddPrev.visible = false;
        } else {
            this.allPositions.unshift(ghost);
            ghost.inAnchor = priorPos.inAnchor = this.getOppositeAnchorIndex(
                priorPos.outAnchor
            );
            ghost.nextPosition!.quickAddPrev.visible = false;
            ghost.quickAddNext.visible = false;
        }

        // Place the ghost in front/behind parent =========================================
        const vector = ghost
            .globalPointFor("position")
            .subtract(
                ghost.getAnchorPoint(
                    moment == "Prior" ? ghost.outAnchor : ghost.inAnchor
                )
            );
        const sibGlobalP = priorPos.globalPointFor("position");
        ghost.position = sibGlobalP.add(vector.multiply(5));
        //=================================================================================

        // const arrow =
        moment === "Prior"
            ? new Arrow(ghost, priorPos, false)
            : new Arrow(priorPos, ghost, true);

        // copy trailer if needed
        if (priorPos.hasTrailer) {
            const clone = priorPos.trailer!.clone();
            clone.attachTo(ghost);
            if (moment === "Prior") {
                ghost.outArrow?.changeTarget(priorPos.trailer!);
            } else if (this.isReversing) {
                priorPos.outArrow?.changeOrigin(priorPos.trailer!);
            }
            clone.updateArrows;
        }

        finishMajorDraw();

        if (addingPosition) {
            addingPosition.stopRecording(ghost);
        }

        return ghost;
    }

    private getAllConnected(): paper.Group {
        const toGroup = new Array<paper.Group | paper.Item>();
        const arrows = new Set<Arrow>();

        for (const pos of this.allPositions) {
            toGroup.push(pos.entireObject);

            for (const arrow of pos.arrows) {
                arrows.add(arrow);
            }

            if (pos.hasTrailer) {
                toGroup.push(pos.trailer!.entireObject);

                for (const arrow of pos.trailer!.arrows) {
                    arrows.add(arrow);
                }
            }
        }

        for (const arrow of arrows) {
            toGroup.push(arrow.entireObject);
        }

        return new paper.Group(toGroup);
    }

    changeColor(colorCode: string, iconHasChanged = false) {
        if (!iconHasChanged && this.color == colorCode) return;

        const changeColorAction = new DiagramElementAction({
            name: "change color",
            elementRef: this
        });

        changeColorAction.startRecording();

        this.arrowColor = colorCode;

        for (const [def, pos] of this.uniqueSymbolDefs()) {
            const newIcon = loadSVGFromPage(
                this.icon,
                colorCode,
                pos.orientation,
                this.isHitAndRun
            );
            newIcon.scale(getIconScale(this.icon));
            def.item = newIcon;
        }

        for (const pos of this.allPositions) {
            pos.color = colorCode;

            if (iconHasChanged) {
                pos.fixSelectionBorder();
                pos.trailer?.reposition();
            }
        }

        changeColorAction.stopRecording();
    }

    private fixSelectionBorder() {
        startMajorDraw();
        const size =
            Math.sqrt(
                Math.pow(this.core.bounds.width, 2) +
                    Math.pow(this.core.bounds.height, 2)
            ) * 1.05;
        this.selectionCircle.fitBounds(
            new paper.Rectangle(this.core.position.subtract(size * 0.6), [
                size * 1.2,
                size * 1.2
            ])
        );
        this.rotationHandle.position = this.selectionCircle.getPointAt(
            this.selectionCircle.length
        );
        this.normalizeSelectionUISize();

        (this.border.segments[0].point = this.core.bounds.topCenter),
            (this.border.segments[1].point = this.core.bounds.topRight),
            (this.border.segments[2].point = this.core.bounds.bottomRight),
            (this.border.segments[3].point = this.core.bounds.bottomLeft),
            (this.border.segments[4].point = this.core.bounds.topLeft);

        this.repositionLabel();

        // this.innerGroup.pivot = this.core.position;
        // this.entireObject.pivot = this.globalPointFor("bounds.center");

        this.updateArrows();
        if (this.hasTrailer) this.trailer!.reposition();
        finishMajorDraw();
    }

    private findDefForOrientation(
        orientation: string
    ): paper.SymbolDefinition | undefined {
        for (const [def, pos] of this.uniqueSymbolDefs()) {
            if (pos.orientation === orientation) return def;
        }

        return undefined;
    }

    normalizeSelectionUISize(scaling?: paper.Point, zoomScale?: number): void {
        if (!zoomScale) zoomScale = getDiagramZoomScale();
        if (!scaling) scaling = this.getNormalizedScaling(zoomScale);

        if (this.quickAddFront) {
            this.quickAddFront.scaling = scaling;
            const p = this.selectionCircle.getPointAt(
                this.selectionCircle.length / 4
            );
            this.quickAddFront.position = p;
        }

        if (this.quickAddBack) {
            this.quickAddBack.scaling = scaling;
            const p = this.selectionCircle.getPointAt(
                (this.selectionCircle.length / 4) * 3
            );
            this.quickAddBack.position = p;
        }

        super.normalizeSelectionUISize(scaling, zoomScale);
    }

    updateQuickAdds() {
        if (
            (this.quickAddBack.visible && this.quickAddFront.visible) ||
            (!this.quickAddBack.visible && !this.quickAddFront.visible)
        ) {
            return;
        }

        if (!this.nextPosition) {
            this.quickAddNext.visible = true;
            this.quickAddPrev.visible = false;
        } else if (!this.prevPosition) {
            this.quickAddPrev.visible = true;
            this.quickAddNext.visible = false;
        }
    }

    changeOrientation(orientation: string) {
        const changeOrientationAction = new DiagramElementAction({
            name: "change orientation",
            elementRef: this
        });
        changeOrientationAction.startRecording();

        startMajorDraw();

        let newCore: paper.SymbolItem;

        // an existing def?
        const existingDef = this.findDefForOrientation(orientation);
        if (existingDef) {
            newCore = existingDef.place();
        } else {
            // determine the new icon
            let newIcon: paper.Item;

            switch (orientation) {
                case "Right":
                case "Left":
                    newIcon = loadSVGFromPage(
                        this.icon,
                        this.color,
                        "Side",
                        this.isHitAndRun
                    );
                    /* note: only left side SVG exist,
                   so if orientation is to be set to "Right",
                   use the Left SVG and flip it! */
                    if (orientation === "Right") {
                        newIcon.scale(-1, 1); // flipped over x axis
                    }
                    break;
                case "Supine":
                    newIcon = loadSVGFromPage(this.icon, this.color, "Side");
                    newIcon.scale(0.5);
                    newIcon.rotate(180);
                    break;
                case "Prostrate":
                    newIcon = loadSVGFromPage(this.icon, this.color, "Bottom");
                    newIcon.scale(0.5);
                    newIcon.rotate(180);
                    break;
                case "Standing":
                    newIcon = loadSVGFromPage(this.icon, this.color, "Top");
                    break;
                default:
                    newIcon = loadSVGFromPage(
                        this.icon,
                        this.color,
                        orientation,
                        this.isHitAndRun
                    );
            }

            newIcon.scale(getIconScale(this.icon));
            newCore = new paper.SymbolDefinition(newIcon).place();
        }

        let hasNewDimensions =
            newCore.bounds.width != this.core.bounds.width ||
            newCore.bounds.height != this.core.bounds.height
                ? true
                : false;

        newCore.copyAttributes(this.core, false);
        this.core.replaceWith(newCore);
        this.setUpCoreEvents();

        if (hasNewDimensions) {
            this.fixSelectionBorder();
        }

        // make sure it's centered in the selection controls
        this.core.position = this.selectionCircle.position;
        this.orientation = orientation;

        finishMajorDraw();

        changeOrientationAction.stopRecording();
    }

    /** Participants from the crash report are hidden instead of deleted */
    private hideAll(
        removeAction: DiagramElementAction | undefined
    ): [paper.Group, LatLng, number] | undefined {
        DiagramObject.deselect(this);
        const allConnected = this.getAllConnected();
        allConnected.remove();
        const participantLatLng = projectPointToLatLng(allConnected.position);
        const zoomLvl = leafletMap.getZoom();
        getLayer(DiagramLayer.main).data.hiddenParticipants[
            this.participantIndex!
        ] = [allConnected.exportJSON(), participantLatLng, zoomLvl];

        if (removeAction) {
            removeAction.stopRecording();
        }

        CrashReportService.recheckDiagrammedParticipants();

        return [allConnected, participantLatLng, zoomLvl];
    }

    async swapIcon(icon: string) {
        const changeIconAction = new DiagramElementAction({
            name: "change icon",
            elementRef: this
        });

        changeIconAction.startRecording();

        startMajorDraw();

        const newIcon = await loadSVGFromFile(`assets/SVG/Animals/${icon}.svg`);
        newIcon.scale(getIconScale(icon));

        const newDef = new paper.SymbolDefinition(newIcon);

        if (this.labelText === this.icon) {
            this.changeLabel(icon);
        }

        /* I discovered that Paper.js has a bug that occurs during its update routine if
            only this.symbolDefinition's item is replaced with an SVG with gradients and then
            the JSON is re-imported. Therefore, undoing/redoing some of the animal icons would cause
            a fatal error. However, creating a new SymbolDefinition seems to solve the issue. */

        for (const p of this.allPositions) {
            const newCore = newDef.place();
            newCore.copyAttributes(p.core, false);
            p.core.replaceWith(newCore);
            p.setUpCoreEvents();
            p.fixSelectionBorder();
            p.icon = icon;
        }

        finishMajorDraw();

        changeIconAction.stopRecording();
    }

    remove(): ReturnType<typeof this.hideAll> {
        let removeAction: DiagramElementAction | undefined = undefined;

        if (DiagramElementAction.prepareForNewAction()) {
            removeAction = new DiagramElementAction({
                name: "remove",
                elementRef: this,
                isLocked: true
            });
            removeAction.startRecording();
        }

        if (this.isPrimary) {
            if (this.isFromCrashReport) {
                return this.hideAll(removeAction);
            } else {
                {
                    this.forAllGhosts((g) => {
                        g.remove();
                    });

                    for (const arrow of this.arrows) {
                        arrow.remove();
                    }
                }
            }
        } else {
            if (this.hasTrailer) {
                this.trailer!.remove(false);
            }

            const [inArrow, outArrow] = [this.inArrow, this.outArrow];

            if (inArrow && outArrow) {
                const newTarget = outArrow.target;
                outArrow.remove();
                inArrow.changeTarget(newTarget);
            } else {
                inArrow?.remove();
                outArrow?.remove();
            }

            if (this.prevPosition) {
                this.prevPosition.outAnchor = this.getOppositeAnchorIndex(
                    this.prevPosition.inAnchor
                );
            }

            if (this.nextPosition) {
                this.nextPosition.inAnchor = this.getOppositeAnchorIndex(
                    this.nextPosition.outAnchor
                );
            }

            if (this.nextPosition && !this.prevPosition) {
                this.nextPosition.quickAddPrev.visible = true;
            } else if (this.prevPosition && !this.nextPosition) {
                this.prevPosition.quickAddNext.visible = true;
            }
        }

        this.allPositions.splice(this.allPositions.indexOf(this), 1);
        super.remove();

        if (removeAction) {
            removeAction.stopRecording();
        }

        return undefined;
    }

    onRemoveFromGroupSelection(): void {
        for (const quickAddPos of [this.quickAddNext, this.quickAddPrev]) {
            const plusSign = quickAddPos.children["plus sign"];
            plusSign.rotation = -this.rotation;
        }
    }
}
